import React, { useEffect, useState } from "react";
import classes from "./SelectBlockChain.module.css";
import { Input, Text } from "components/common";
import clsx from "clsx";

const SelectBlockChain = ({ setCryptoState, className }) => {
  const [email, setEmail] = useState("");

  useEffect(() => {
    setCryptoState("email");
  }, []);
  return (
    <div className={clsx(classes.wrapper, className)}>
      <div className={classes.infoContainer}>
        <Text sm primaryDefault semiBold>
          Enter email to receive payment updates
        </Text>
      </div>
      <Input
        type={email}
        value={email}
        setValue={setEmail}
        placeholder="Email"
      />
    </div>
  );
};

export default SelectBlockChain;
