import { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { FiSearch } from "react-icons/fi";

import { FaCheck, FaAngleUp, FaAngleDown } from "react-icons/fa6";

import classes from "./WalletDropdown.module.css";
import useOnClickOutside from "hooks";
import {
  avaxLogo,
  bnbLogo,
  btcLogo,
  daiLogo,
  dashLogo,
  dogeLogo,
  ethLogo,
  ltcLogo,
  usdtLogo,
} from "images";
import { Heading, Text } from "components/common";
import { useSelector } from "react-redux";

const WalletDropdown = ({
  isActive,
  setIsActive,
  selectedValue,
  onSelect,
  type2,
}) => {

  const [listItems, setListItems] = useState([]);

  let balances = useSelector((state) => state.balances.balances);
  let currencies = useSelector((state) => state.currencies.currencies);


  useEffect(() => {
    let list = [];
    balances.forEach((el) => {
      currencies.forEach((currency) => {
        if (el.currency == currency.id) {
          list.push({
            img: currency.logo,
            name: currency.name,
            id: currency.id,
            price: el.amount,
          });
        }
      });
    });
    setListItems(list);
  }, [balances, currencies]);




  const [filteredItems, setFilteredItems] = useState(listItems);
  const inputRef = useRef();

  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    inputRef.current.focus();
  }, [isActive]);

  useEffect(() => {
    setFilteredItems(
      listItems?.filter((el) =>
        el.name?.toLowerCase().includes(searchValue.toLowerCase())
      )
    );
  }, [searchValue]);
  const ref = useRef();

  useOnClickOutside(ref, () => setIsActive(false));
  return (
    <div
      className={clsx(classes.dropdown, type2 && classes.dropdown2)}
      ref={ref}
    >
      <div
        className={clsx(
          classes.labelContainer,
          type2 && classes.labelContainer2
        )}
        onClick={() => setIsActive((prev) => !prev)}
      >
        {selectedValue ? (
          <div className={clsx(classes.selectedValue)}>
            <img src={selectedValue.img} alt="#" className={classes.img} />
            <Heading base className={classes.left}>
              {selectedValue.name}
            </Heading>

            {!type2 && (
              <Text base textTeritary className={classes.price}>
                {selectedValue.price}
              </Text>
            )}
          </div>
        ) : (
          <Text textTerirary base>
            Select
          </Text>
        )}
        {isActive ? (
          <FaAngleUp className={classes.arrow} />
        ) : (
          <FaAngleDown className={classes.arrow} />
        )}
      </div>
      <div className={clsx(classes.dropdownMain, isActive && classes.active)}>
        <div className={classes.searchContainer}>
          <FiSearch className={classes.searchIcon} />
          <input
            ref={inputRef}
            type="search"
            placeholder="Search"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </div>
        <div className={classes.list}>
          {filteredItems?.map((el, idx) => {
            return (
              <div
                key={"lang-list-item-" + idx}
                className={clsx(
                  classes.listItem,
                  selectedValue.name === el.name && classes.active
                )}
                onClick={() => {
                  setSearchValue("");
                  onSelect(el.id);
                  setIsActive(false);
                }}
              >
                <img src={el.img} alt="#" className={classes.img} />
                <p className={classes.left}>{el.name}</p>

                {selectedValue.name === el.name ? (
                  <FaCheck className={classes.checkmark} />
                ) : (
                  <Text base textTeritary className={classes.price}>
                    {el.price}
                  </Text>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default WalletDropdown;
