import React, { useEffect, useState } from "react";
import classes from "./Checkout.module.css";
import PaymentMethod from "components/Checkout/PaymentMethod/PaymentMethod";
import { Button, Heading, Text } from "components/common";
import clsx from "clsx";
import { FaAngleLeft } from "react-icons/fa";
import PayNow from "components/Checkout/PayNow/PayNow";
import SelectBlockChain from "components/Checkout/SelectBlockChain/SelectBlockChain";
import SelectCoin from "components/Checkout/SelectCoin/SelectCoin";
import SelectNetwork from "components/Checkout/SelectNetwork/SelectNetwork";

import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import SupportedCoin from "components/Checkout/PayNow/SupportedCoin/SupportedCoin";

const Checkout = () => {
  const [step, setStep] = useState(1);
  const stepsTitle = [
    "Select Payment Method",
    "Select Coin",
    "Select Network",
    "Select Blockchain",
    "",
    "Supported Tokens",
  ];

  let { payment_id } = useParams();
  let [paymentDetails, setPaymentDetails] = useState(null);
  let [footerAmount, setFooterAmount] = useState(0);
  let [currencies, setCurrencies] = useState([]);
  let [networks, setNetworks] = useState([]);
  let [selectedCoin, setSelectedCoin] = useState({});
  const [checkoutData, setCheckoutData] = useState(null);
  const [paymentGenerateInput, setPaymentGenerateInput] = useState({
    coin: "usdt",
    network: "bsc",
  });
  const [refresh, setRefresh] = useState(1);

  const navigate = useNavigate();
  async function fetchPaymentDetails() {
    try {
      let api =
        process.env.REACT_APP_API_URL + "/payments/status/" + payment_id;
      console.log(api);
      let { data, status } = await axios.get(api);
      setCheckoutData(data);

      if (data?.status == "Completed") {
        localStorage.setItem("completed_payment", JSON.stringify(data));
        navigate("/checkout-success/" + payment_id);
      }
      setStep(5);
      if (status == 404) {
      }
      setPaymentDetails(data);
    } catch (e) {
      console.log(e);
    }
  }

  async function generateAddress(payment_id, protocol) {
    try {
      let api =
        process.env.REACT_APP_API_URL + "/payments/generate_address";
      let { data, status } = await axios.post(api, {
        payment_id: payment_id,
        protocol: protocol
      });
      if (data?.status == "Completed") {
        localStorage.setItem("completed_payment", JSON.stringify(data));
        navigate("/checkout-success/" + payment_id);
      }
      setStep(5);
      if (status == 404) {
      }
      setPaymentDetails(data);
      setCheckoutData(data);
      return data;
    } catch (e) {
      console.log(e);
    }


  }

  useEffect(() => {
    if (!checkoutData) {
      return;
    }
    if (checkoutData.status.toLowerCase() == "completed") {
      navigate("/checkout-success/" + checkoutData.payment_id);
    }
  }, [checkoutData])

  async function fetchPaymentRequestDetails() {
    try {
      let api =
        process.env.REACT_APP_API_URL + "/payments/request/get/" + payment_id;

      console.log(api);
      let { data, status } = await axios.get(api);

      if (status == 404) {
        console.log("404");
        fetchPaymentDetails();
        return;
      }
      console.log(data);


      setFooterAmount(data.amount)
      if (data?.linked_payments?.length > 0) {
        let linked_payment_id = data.linked_payments[0];
        console.log("--------", linked_payment_id);
        navigate("/checkout/" + linked_payment_id);
      }
      // setPaymentDetails(data);
    } catch (e) {
      console.log(e);
    }
  }

  async function generatePaymentDetailsFromRequest() {
    try {
      let api =
        process.env.REACT_APP_API_URL +
        "/payments/request/generate_payment/" +
        payment_id;
      let body = {
        pay_currency: paymentGenerateInput.coin,
        pay_network: paymentGenerateInput.network,
      };
      let { data } = await axios.post(api, body);
      console.log(data);
      return data;
    } catch (e) {
      console.log(e);
    }
  }
  async function getCurrencies() {
    try {
      let api = process.env.REACT_APP_API_URL + "/currencies";
      let { data } = await axios.get(api);
      // setPaymentDetails(data);

      // sort by ui_priority

      let arr = data.sort((a, b) => a.ui_priority - b.ui_priority);
      setCurrencies(arr);
    } catch (e) {
      console.log(e);
    }
  }

  async function getNetworks() {
    try {
      let api = process.env.REACT_APP_API_URL + "/networks";
      console.log(api);
      let { data } = await axios.get(api);
      console.log(data);
      setNetworks(data);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    fetchPaymentDetails();
  }, [refresh]);

  useEffect(() => {
    fetchPaymentRequestDetails();
    fetchPaymentDetails();
    getCurrencies();
    getNetworks();
  }, [payment_id]);

  useEffect(() => {
    console.log(selectedCoin);
  }, [selectedCoin]);

  const [paymentMethod, setPaymentMethod] = useState("crypto");
  const [crypto_state, setCryptoState] = useState();

  function payButton() {
    if (paymentMethod === "crypto") {
      if (crypto_state === "email") {
        return {
          text: "Generate Payment",
          onClick: async () => {
            let data = await generatePaymentDetailsFromRequest();
            setCheckoutData(data);
            setStep(5);
          },
        };
      } else {
        return {
          text: "Next",
          onClick: () => setStep((prev) => prev + 1),
        };
      }
    }

    if (paymentMethod === "triplepay") {
      return {
        text: "Open Triplepay App",
        onClick: () => setStep(5),
      };
    }

    if (paymentMethod === "uns") {
      return {
        text: "Pay with UNS",
        onClick: () => setStep(5),
      };
    }
  }
  let payB = payButton();
  return (
    <section className={classes.wrapper}>
      <div className={clsx(classes.header, step > 4 && classes.header2)}>
        <Heading xl2>{stepsTitle[step - 1]}</Heading>

        {step < 5 && (
          <div className={classes.steps}>
            {Array.from({ length: 4 }).map((_, stepId) => (
              <button
                key={stepId}
                className={clsx(
                  classes.step,
                  stepId + 1 <= step && classes.activeStep
                )}
                onClick={() => setStep(stepId + 1)}
              ></button>
            ))}
          </div>
        )}
        {step != 5 && step != 1 && (
          <Button
            transparent
            className={classes.backButton}
            onClick={() => setStep((prev) => prev - 1)}
          >
            <FaAngleLeft />
            Back
          </Button>
        )}
      </div>
      <div className={classes.container}>
        {step === 1 && (
          <PaymentMethod
            className={classes.padding}
            setPaymentMethod={setPaymentMethod}
          />
        )}
        {step === 2 && (
          <SelectCoin
            className={classes.padding}
            setSelectedCoin={setSelectedCoin}
            currencies={currencies}
            setPaymentGenerateInput={setPaymentGenerateInput}
            paymentGenerateInput={paymentGenerateInput}
          />
        )}
        {step === 3 && (
          <SelectNetwork
            className={classes.padding}
            networks={networks}
            selectedCoin={selectedCoin}
            setPaymentGenerateInput={setPaymentGenerateInput}
            paymentGenerateInput={paymentGenerateInput}
          />
        )}
        {step === 4 && (
          <SelectBlockChain
            className={classes.padding}
            setCryptoState={setCryptoState}
          />
        )}

        {step === 5 && (
          <>
            <PayNow
              generateAddress={generateAddress}
              setCheckoutData={setCheckoutData}
              setRefresh={setRefresh}
              checkoutData={checkoutData}
              currencies={currencies}
              networks={networks}
              setStep={setStep}
            />
          </>
        )}

        {step === 6 && (
          <>
            <SupportedCoin currencies={currencies} />
          </>
        )}
      </div>

      {step < 5 && (
        <div className={classes.bottomBarContainer}>
          <div className={classes.bottomBar}>
            <div className={classes.amountContainer}>
              <Text sm secondary>
                Amount
              </Text>
              <Text base semiBold>
                {paymentDetails?.amount
                  ? paymentDetails?.amount + " USD"
                  : footerAmount ? Math.trunc(Number(footerAmount).toFixed(2) * 100) / 100 + " USD" : "None"}
              </Text>
            </div>
            <Button onClick={payB.onClick}>{payB.text}</Button>
          </div>
        </div>
      )}
    </section>
  );
};

export default Checkout;
