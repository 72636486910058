import { useRef } from "react";
import clsx from "clsx";

import classes from "./ProfileDropdown.module.css";
import useOnClickOutside from "hooks";
import { NavLink } from "react-router-dom";
import { Heading, Text } from "components/common";

const ProfileDropdown = ({
  isActive,
  setIsActive,

  children,
  userInfo,
}) => {
  const ref = useRef();

  useOnClickOutside(ref, () => setIsActive(false));
  return (
    <div className={clsx(classes.dropdown)} ref={ref}>
      {children}
      <div className={clsx(classes.dropdownMain, isActive && classes.active)}>
        <div className={classes.userInfo}>
          <Heading lg semiBold>
            {userInfo?.name}
          </Heading>
          <Text sm secondary>
            <a href={`mailto:${userInfo?.email}`}>{userInfo?.email}</a>
          </Text>
        </div>
        <div className={classes.list}>
          <NavLink
            to="/accounts"
            className={({ isActive }) =>
              isActive ? clsx(classes.item, classes.active) : classes.item
            }
          >
            Account Settings
          </NavLink>{" "}
          <NavLink
            to="/manage-api-key"
            className={({ isActive }) =>
              isActive ? clsx(classes.item, classes.active) : classes.item
            }
          >
            Manage API Key
          </NavLink>{" "}
          <NavLink
            to="/affiliate"
            className={({ isActive }) =>
              isActive ? clsx(classes.item, classes.active) : classes.item
            }
          >
            Affiliate
          </NavLink>{" "}
          <NavLink
            to="/login"
            className={({ isActive }) =>
              isActive
                ? clsx(classes.item, classes.active, classes.logout)
                : clsx(classes.item, classes.logout)
            }
          >
            Logout
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default ProfileDropdown;
