import React, { useState, useEffect, useRef } from "react";
import { IoMdClose } from "react-icons/io";
import { AiOutlineAlignRight } from "react-icons/ai";
import classes from "./Navbar.module.css";

import { Link } from "react-router-dom";

import {
  currencyIcon,
  lanugageIcon,
  mainLogo,
  notificationIcon,
  userIcon,
} from "images";
import LanguageDropdown from "components/Wallet/LanguageDropdown/LanguageDropdown";
import clsx from "clsx";
import ProfileDropdown from "components/Wallet/ProfileDropdown/ProfileDropdown";
import NotificationDropdown from "components/Wallet/NotificationDropdown/NotificationDropdown";
import CurrencyDropdown from "components/Wallet/CurrencyDropdown/CurrencyDropdown";
import { Button } from "components/common";

const Navbar = () => {
  const [sidebar, setSidebar] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  //dropdown

  const [showNotificationDropdown, setShowNotificationDropdown] =
    useState(false);

  //language dropdown
  const [showLanguageropdown, setShowLanguageropdown] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("English");

  //currencyDropdown
  const [showCurrencyropdown, setShowCurrencyropdown] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState("USD");

  //profileDropdown
  const [showProfileDropdown, setShowProfileDropdown] = useState(false);

  const userInfo = { name: "Frederick Johnson", email: "example@mail.com" };

  const handleScroll = () => {
    if (window.scrollY > 90) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div
      className={[classes.wrapper, isScrolled && classes.wrapperBg].join(" ")}
    >
      <header className={[classes.header, "container"].join(" ")}>
        <Link
          className={[classes.navItem, classes.logoContainer].join(" ")}
          to="/"
          onClick={() => setSidebar((prev) => !prev)}
        >
          {" "}
          <img src={process.env.REACT_APP_LOGO_WORD_URL} alt="#" className={classes.logo} />
        </Link>{" "}
        <Button
          to="/dashboard"
          transparent
          className={clsx(classes.walletButton, classes.mobileButton)}
        >
          Back To Dashboard
        </Button>
        <div
          className={clsx(classes.buttonContainer, sidebar && classes.sidebar)}
        >
          {" "}
          <Button
            to="/dashboard"
            transparent
            className={clsx(classes.walletButton, classes.dekstopButton)}
          >
            Back To Dashboard
          </Button>
          <NotificationDropdown
            isActive={showNotificationDropdown}
            setIsActive={setShowNotificationDropdown}
          >
            <div
              className={clsx(
                showNotificationDropdown && classes.activeIconContainer,
                classes.iconContainer
              )}
              onClick={() => setShowNotificationDropdown((prev) => !prev)}
            >
              <img src={notificationIcon} alt="#" className={classes.icon} />
            </div>
          </NotificationDropdown>{" "}
          <LanguageDropdown
            isActive={showLanguageropdown}
            selectedValue={selectedLanguage}
            setIsActive={setShowLanguageropdown}
            onSelect={(val) => setSelectedLanguage(val)}
          >
            <div
              className={clsx(
                showLanguageropdown && classes.activeIconContainer,
                classes.iconContainer
              )}
              onClick={() => setShowLanguageropdown((prev) => !prev)}
            >
              <img src={lanugageIcon} alt="#" className={classes.icon} />
            </div>
          </LanguageDropdown>{" "}
          <CurrencyDropdown
            isActive={showCurrencyropdown}
            selectedValue={selectedCurrency}
            setIsActive={setShowCurrencyropdown}
            onSelect={(val) => setSelectedCurrency(val)}
          >
            <div
              className={clsx(
                showCurrencyropdown && classes.activeIconContainer,
                classes.iconContainer
              )}
              onClick={() => setShowCurrencyropdown((prev) => !prev)}
            >
              <img src={currencyIcon} alt="#" className={classes.icon} />
            </div>
          </CurrencyDropdown>{" "}
          <ProfileDropdown
            isActive={showProfileDropdown}
            setIsActive={setShowProfileDropdown}
            userInfo={userInfo}
          >
            <div
              className={clsx(
                showProfileDropdown && classes.activeIconContainer,
                classes.iconContainer
              )}
              onClick={() => setShowProfileDropdown((prev) => !prev)}
            >
              <img src={userIcon} alt="#" className={classes.icon} />
            </div>
          </ProfileDropdown>{" "}
          <IoMdClose
            className={classes.closeIcon}
            onClick={() => setSidebar((prev) => !prev)}
          />
        </div>{" "}
        <AiOutlineAlignRight
          className={classes.openIcon}
          onClick={() => setSidebar((prev) => !prev)}
        />
      </header>
    </div>
  );
};

export default Navbar;
