import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from 'js-cookie';

export const fetchNetworks = createAsyncThunk("fetch_networks", async (args, {
    rejectWithValue
}) => {

    try {
        let base_url = process.env.REACT_APP_API_URL;
        let route = "/networks"
        let url = base_url + route;

        console.log(url)
        let data = await axios.get(url, {
            headers: {
                "Authorization": `Bearer ${Cookies.get("token")}`
            }
        });
        console.log(data)
        return data.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})


const fetchNetworkslice = createSlice({
    name: 'fetch_networks',
    initialState: {
        networks: [],
        isLoading: false,
        isFailed: false
    },
    reducers: {
    },
    // extra reducer use builder callback
    extraReducers: (builder) => {
        builder.addCase(fetchNetworks.pending, (state, action) => {
            state.isLoading = true
            state.isFailed = false
        })
        builder.addCase(fetchNetworks.fulfilled, (state, action) => {
            state.isLoading = false
            state.isFailed = false
            state.networks = action.payload
        })
        builder.addCase(fetchNetworks.rejected, (state, action) => {
            state.isLoading = false
            state.isFailed = true
        })
    }

})

export default fetchNetworkslice.reducer