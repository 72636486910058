import React, { useState } from "react";
import classes from "./ReceiveModal.module.css";
import Modal from "components/common/Modal/Modal";
import { Button, Heading, Text } from "components/common";
import { IoClose } from "react-icons/io5";
import WalletDropdown from "components/Wallet/WalletDropdown/WalletDropdown";
import NetworkDropdown from "components/Wallet/NetworkDropdown/NetworkDropdown";
import { copyIcon, qrCode } from "images";
import { copyToClipboard } from "utils/utils";

const ReceiveModal = ({ isActive, onClose }) => {
  const [generateAddress, setGenerateAddress] = useState(false);
  const [showWalletDropdown, setShowWalletDropdown] = useState(false);
  const [selectedWallet, setSelectedWallet] = useState("");

  const [showNetworkDropdown, setShowNetworkDropdown] = useState(false);
  const [selectedNetwork, setSelectedNetwork] = useState("");

  return (
    <Modal isActive={isActive} onClose={onClose}>
      <div className={classes.wrapper}>
        <div className={classes.header}>
          <Heading primary lg>
            Receive
          </Heading>
          <button className={classes.closeButton} onClick={onClose}>
            <IoClose className={classes.closeIcon} />
          </button>
        </div>
        <div className={classes.selectContainer}>
          <Heading xl semiBold className={classes.label}>
            Select Wallet
          </Heading>
          <WalletDropdown
            isActive={showWalletDropdown}
            setIsActive={setShowWalletDropdown}
            selectedValue={selectedWallet}
            onSelect={(val) => setSelectedWallet(val)}
          />{" "}
          <Heading xl semiBold className={classes.label}>
            Select Network 
          </Heading>
          <div className={classes.networkContainer}>
            <NetworkDropdown
              className={!selectedWallet && classes.disabled}
              isActive={selectedWallet && showNetworkDropdown}
              setIsActive={setShowNetworkDropdown}
              selectedValue={selectedNetwork}
              onSelect={(val) => setSelectedNetwork(val)}
            />
            {selectedWallet && (
              <Text primary sm className={classes.info}>
                Available network for
                <span className={classes.wallet}> BTC</span> wallet.
              </Text>
            )}
            {!selectedWallet && (
              <Text primary sm className={classes.info}>
                You need to select wallet first.
              </Text>
            )}
          </div>
        </div>

        <div className={classes.generateAddressContainer}>
          {!generateAddress && (
            <Button
              onClick={() => {
                setGenerateAddress(true);
              }}
            >
              Generate Address
            </Button>
          )}
          {generateAddress && (
            <div className={classes.addressContainer}>
              <div className={classes.qrCodeContainer}>
                {" "}
                <img src={qrCode} alt="QrCode" className={classes.qrCode} />
                <Button primitive800>Share QR Code</Button>
              </div>
              <div className={classes.addressAndInfo}>
                <Heading semiBold lg>
                  Share the USDT wallet address to the sender to receive the
                  currency.
                </Heading>
                <div className={classes.copyAddress}>
                  <Text base semiBold primary className={classes.address}>
                    0xcaa80849a628d56f262655dd21441ae6096dfd16
                  </Text>
                  <button
                    className={classes.copyContainer}
                    onClick={() =>
                      copyToClipboard(
                        "0xcaa80849a628d56f262655dd21441ae6096dfd16"
                      )
                    }
                  >
                    <img
                      src={copyIcon}
                      alt="Copy"
                      className={classes.copyIcon}
                    />
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
        {!generateAddress && (
          <div className={classes.buttonContainer}>
            <Button primitive800 onClick={onClose}>
              Close
            </Button>{" "}
            <Button  onClick={() => {}}>
              Generate New Address
            </Button>
          </div>
        )}
      </div>
      ;
    </Modal>
  );
};

export default ReceiveModal;
