import React, { useState } from "react";
import classes from "./ConvertModal.module.css";
import Modal from "components/common/Modal/Modal";
import { Button, Heading, Input, Text } from "components/common";
import { IoClose } from "react-icons/io5";
import { HiOutlineArrowDown } from "react-icons/hi";

import clsx from "clsx";
import { handleKeyDown } from "hooks";
import WalletDropdown from "components/Wallet/WalletDropdown/WalletDropdown";
import TransactionDetailsModal from "Modals/TransactionDetailsModal/TransactionDetailsModal";
const transactionDetails = [
  {
    key: "Transaction Type",
    value: "Convert",
  },
  {
    key: "Transaction ID",
    value: "ABC12345678",
  },
  {
    key: "Address",
    value: "VpQP4yWjq2SKccK5d8o2BxW7",
  },
  {
    key: "Transfer From",
    value: "USDT Account",
  },
  {
    key: "Amount",
    value: "1.00 USDT",
  },
  {
    key: "Received Date",
    value: "13-5-2024",
  },
  {
    key: "Status",
    value: "Completed",
  },
];
const ConvertModal = ({ isActive, onClose }) => {
  const [amount, setAmount] = useState("");
  const [convertToAmount, setCovertToAmount] = useState("");

  const [showWalletDropdown, setShowWalletDropdown] = useState(false);
  const [selectedWallet, setSelectedWallet] = useState("");

  // convertTo
  const [showConvertToWalletDropdown, setShowConvertToWalletDropdown] =
    useState(false);
  const [convertToSelectedWallet, setConvertToSelectedWallet] = useState("");

  const [showTransactionDetailsModal, setShowTransactionDetailsModal] =
    useState(false);
  return (
    <>
      <Modal isActive={isActive} onClose={onClose}>
        <div className={classes.wrapper}>
          <div className={classes.header}>
            <Heading primary lg>
              Convert
            </Heading>
            <button className={classes.closeButton} onClick={onClose}>
              <IoClose className={classes.closeIcon} />
            </button>
          </div>
          <div className={classes.amountWrapper}>
            {" "}
            <div className={classes.amountContainer}>
              <div className={classes.inputContanier}>
                <Heading base semiBold>
                  Enter amount
                </Heading>
                <Input
                  type="number"
                  onKeyDown={handleKeyDown}
                  value={amount}
                  setValue={setAmount}
                  placeholder="0.00"
                  className={classes.input}
                />
              </div>
              <div className={classes.balanceAndWallet}>
                <Text base textTeritary>
                  Balance: 0.00
                </Text>
                <WalletDropdown
                  type2
                  isActive={showWalletDropdown}
                  setIsActive={setShowWalletDropdown}
                  selectedValue={selectedWallet}
                  onSelect={(val) => setSelectedWallet(val)}
                />
              </div>

              <button className={classes.convertButton}>
                <HiOutlineArrowDown className={classes.convertIcon} />
              </button>
            </div>{" "}
            <div className={clsx(classes.amountContainer, classes.convertTo)}>
              <div className={classes.inputContanier}>
                <Heading base semiBold>
                  Convert To
                </Heading>
                <Input
                  type="number"
                  onKeyDown={handleKeyDown}
                  value={convertToAmount}
                  setValue={setCovertToAmount}
                  placeholder="0.00"
                  className={classes.input}
                />
              </div>
              <div className={classes.balanceAndWallet}>
                <Text base textTeritary>
                  Balance: 0.00
                </Text>
                <WalletDropdown
                  type2
                  isActive={showConvertToWalletDropdown}
                  setIsActive={setShowConvertToWalletDropdown}
                  selectedValue={convertToSelectedWallet}
                  onSelect={(val) => setConvertToSelectedWallet(val)}
                />
              </div>
            </div>
          </div>

          <div className={classes.rateContainer}>
            <Text secondary base>
              Rate
            </Text>
            <Text textWhite semiBold className={classes.rate}>
              $1.0 USDT = 0.000024 BTC
            </Text>
          </div>
          <div className={classes.buttonContainer}>
            <Button primitive800 onClick={onClose}>
              Cancel
            </Button>{" "}
            <Button
              onClick={() => {
                onClose();
                setShowTransactionDetailsModal(true);
              }}
            >
              Convert
            </Button>
          </div>
        </div>
      </Modal>{" "}
      <TransactionDetailsModal
        details={transactionDetails}
        isActive={showTransactionDetailsModal}
        onClose={() => setShowTransactionDetailsModal(false)}
      />
    </>
  );
};

export default ConvertModal;
