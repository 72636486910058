import React, { useState } from "react";
import classes from "./WithdrawModal.module.css";
import Modal from "components/common/Modal/Modal";
import { Button, Heading, Input, Text } from "components/common";
import { IoClose } from "react-icons/io5";
import WalletDropdown from "components/Wallet/WalletDropdown/WalletDropdown";
import NetworkDropdown from "components/Wallet/NetworkDropdown/NetworkDropdown";
import { smallQrCode } from "images";
import clsx from "clsx";
import { handleKeyDown } from "hooks";
import TransactionDetailsModal from "Modals/TransactionDetailsModal/TransactionDetailsModal";
import { useSelector } from "react-redux";
const WithdrawModal = ({ isActive, onClose }) => {
  const [showWalletDropdown, setShowWalletDropdown] = useState(false);
  const [selectedWallet, setSelectedWallet] = useState("");
  const [showNetworkDropdown, setShowNetworkDropdown] = useState(false);
  const [selectedNetwork, setSelectedNetwork] = useState("");
  const [address, setAddress] = useState("");
  const [amount, setAmount] = useState("");

  const [showTransactionDetailsModal, setShowTransactionDetailsModal] =
    useState(false);


  let balances = useSelector((state) => state.balances.balances);
  let currencies = useSelector((state) => state.currencies.currencies);
  return (
    <>
      <Modal isActive={isActive} onClose={onClose}>
        <div className={classes.wrapper}>
          <div className={classes.header}>
            <Heading primary lg>
              Withdraw
            </Heading>
            <button className={classes.closeButton} onClick={onClose}>
              <IoClose className={classes.closeIcon} />
            </button>
          </div>
          <div className={classes.selectContainer}>
            <Heading xl semiBold className={classes.label}>
              Select Wallet
            </Heading>
            <WalletDropdown
              isActive={showWalletDropdown}
              setIsActive={setShowWalletDropdown}
              selectedValue={selectedWallet}
              onSelect={(val) => setSelectedWallet(val)}
            />{" "}
            <Heading xl semiBold className={classes.label}>
              Select Network
            </Heading>
            <div className={classes.networkContainer}>
              <NetworkDropdown
                selectedWallet={selectedWallet}
                className={!selectedWallet && classes.disabled}
                isActive={selectedWallet && showNetworkDropdown}
                setIsActive={setShowNetworkDropdown}
                selectedValue={selectedNetwork}
                onSelect={(val) => setSelectedNetwork(val)}
              />
              {selectedWallet && (
                <Text primary sm className={classes.info}>
                  Available network for
                  <span className={classes.wallet}> BTC</span> wallet.
                </Text>
              )}
              {!selectedWallet && (
                <Text primary sm className={classes.info}>
                  You need to select wallet first.
                </Text>
              )}
            </div>{" "}
            {/* Enter Address */}
            <Heading xl semiBold className={classes.label}>
              Enter Address
            </Heading>
            <div className={classes.inputContainer}>
              <Input
                value={address}
                setValue={setAddress}
                className={classes.input}
              />
              <div className={classes.qrCodeContainer}>
                <img src={smallQrCode} alt="#" className={classes.qrCode} />
              </div>
            </div>{" "}
            {/* enter Amount */}
            <Heading xl semiBold className={classes.label}>
              Enter Amount
            </Heading>
            <div className={classes.amountInputContainer}>
              <Input
                value={amount}
                setValue={setAmount}
                type="number"
                onKeyDown={handleKeyDown}
                className={clsx(classes.input, classes.amountInput)}
                placeholder="0.00"
              />
              {amount && (
                <Text primary sm className={classes.info}>
                  Platform Fee
                  <span className={classes.fee}>1.00 USD</span>
                </Text>
              )}
              {!amount && (
                <Text primary sm className={classes.info}>
                  Enter amount to calculate fee
                </Text>
              )}
            </div>{" "}
          </div>
          <div className={classes.bottombar}>
            <div className={classes.receiveContainer}>
              <Text sm secondary>
                You will receive
              </Text>
              <Heading lg semiBold>
                0.00 USDT
              </Heading>
            </div>
            <div className={classes.buttonContainer}>
              <Button primitive800 onClick={onClose}>
                Cancel
              </Button>{" "}
              <Button
                onClick={() => {
                  onClose();
                  setShowTransactionDetailsModal(true);
                }}
              >
                Withdraw
              </Button>
            </div>
          </div>
        </div>
        ;
      </Modal>
    </>
  );
};

export default WithdrawModal;
