import React, { useEffect, useState } from "react";
import classes from "./MerchantInfo.module.css";
import { Button, Heading, Text } from "components/common";
import { formatter } from "utils/utils";
import {
  convertIcon,
  receiveIcon,
  transferIcon,
  usdtLogo,
  withdrawIcon,
} from "images";
import ReceiveModal from "Modals/ReceiveModal/ReceiveModal";
import WithdrawModal from "Modals/WithdrawModal/WithdrawModal";
import TransactionDetailsModal from "Modals/TransactionDetailsModal/TransactionDetailsModal";
import TransferModal from "Modals/TransferModal/TransferModal";
import ConvertModal from "Modals/ConvertModal/ConvertModal";
import { useSelector } from "react-redux";

const MerchantInfo = ({ }) => {
  const [showReceiveModal, setShowReceiveModal] = useState(false);

  const [showWithdrawModal, setShowWithdrawModal] = useState(false);

  const [showTransferModal, setShowTransferModal] = useState(false);

  const [showCovertModal, setShowConvertModal] = useState(false);

  let [balance, setBalance] = useState(0);

  let profile = useSelector((state) => state.profiles);
  let profiles = profile.profiles;
  let selected = profile.selected_profile;

  let selected_exists = profiles.find((el) => el.profile_id === selected);
  let balances = useSelector((state) => state.balances.balances);
  // setBalance(balances.find(el => el.currency === "usdt")?.balance);

  useEffect(() => {
    setBalance(balances.find((el) => el.currency === "usdt")?.balance);
  }, [balances]);
  return (
    <>
      <section className={classes.wrapper}>
        <div className={classes.header}>
          <div className={classes.titleContainer}>
            <Text primary base>
              Merchant
            </Text>
            <Heading xl2>{selected_exists?.name}</Heading>
          </div>
          <Button onClick={() => { }}>API Documentation</Button>
        </div>

        <div className={classes.infoContainer}>
          <div className={classes.balanceWrapper}>
            <Text primary base>
              Available balance
            </Text>
            <div className={classes.balanceContainer}>
              <Heading xl4>
                {balance}
              </Heading>
              <div className={classes.currencyContainer}>
                <img src={usdtLogo} alt="#" className={classes.currencyLogo} />
                <Text sm primary className={classes.currencyName}>
                  USDT
                </Text>
              </div>
            </div>
            <Text textTeritary base>
              ≈ ${balance}
            </Text>
          </div>
          <div className={classes.buttonContainer}>
            {/* <Button
              primitive950
              onClick={() => {
                setShowReceiveModal(true);
              }}
              className={classes.button}
            >
              Receive <img src={receiveIcon} alt="#" className={classes.icon} />
            </Button> */}
            {/* <Button
              primitive950
              onClick={() => {
                setShowWithdrawModal(true);
              }}
              className={classes.button}
            >
              Withdraw
              <img src={withdrawIcon} alt="#" className={classes.icon} />
            </Button> */}
            <Button
              primitive950
              onClick={() => {
                setShowTransferModal(true);
              }}
              className={classes.button}
            >
              Transfer
              <img src={transferIcon} alt="#" className={classes.icon} />
            </Button>
            <Button
              primitive950
              onClick={() => {
                setShowConvertModal(true);
              }}
              className={classes.button}
            >
              Convert <img src={convertIcon} alt="#" className={classes.icon} />
            </Button>
          </div>
        </div>
      </section>
      <ReceiveModal
        isActive={showReceiveModal}
        onClose={() => setShowReceiveModal(false)}
      />
      <WithdrawModal
        isActive={showWithdrawModal}
        onClose={() => setShowWithdrawModal(false)}
      />
      <TransferModal
        isActive={showTransferModal}
        onClose={() => setShowTransferModal(false)}
      />{" "}
      <ConvertModal
        isActive={showCovertModal}
        onClose={() => setShowConvertModal(false)}
      />
    </>
  );
};

export default MerchantInfo;
