import React from "react";
import classes from "./DeleteApiKeyModal.module.css";
import Modal from "components/common/Modal/Modal";
import { Button, Heading } from "components/common";

import { notFound } from "images";

const DeleteApiKeyModal = ({ isActive, onClose }) => {
  return (
    <Modal
      isActive={isActive}
      onClose={() => {
        onClose();
      }}
    >
      <div className={classes.wrapper}>
        <img src={notFound} alt="#" className={classes.img} />
        <Heading xl2 textCenter>
          Are you sure you want to delete this api key.
        </Heading>{" "}
        <div className={classes.buttonContainer}>
          <Button
            primitive800
            className={classes.button}
            onClick={() => onClose()}
          >
            Cancel
          </Button>
          <Button className={classes.button} onClick={() => onClose()}>
            Delete
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteApiKeyModal;
