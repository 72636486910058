import { useRef, useState } from "react";
import clsx from "clsx";

import classes from "./NotificationDropdown.module.css";
import useOnClickOutside from "hooks";

import { Button, Heading, Text } from "components/common";
import { FaAngleRight } from "react-icons/fa6";
import { accountIcon, announcement, inbox } from "images";
const allNotifications = [
  {
    date: "11th April 2024",
    notifications: [
      {
        icon: accountIcon,
        title: "Account Login",
        time: "14:24",
        info: "You have logged in your account from Windows Chrome 120.",
        details: {
          device: "Chrome 120",
          os: "Windows 11",
          location: "Dhaka, Bangladesh",
          ip: "103.97.34.8",
        },
      },
      {
        icon: announcement,
        title: "Announcement",
        time: "14:24",
        info: "Complete Tasks in the Welcome Back Challenge & Join the Competition.",
        details: {
          device: "Chrome 120",
          os: "Windows 11",
          location: "Dhaka, Bangladesh",
          ip: "103.97.34.8",
        },
      },
    ],
  },
  {
    date: "11th April 2024",
    notifications: [
      {
        icon: accountIcon,
        title: "Account Login",
        time: "14:24",
        info: "You have logged in your account from Windows Chrome 120.",
        details: {
          device: "Chrome 120",
          os: "Windows 11",
          location: "Dhaka, Bangladesh",
          ip: "103.97.34.8",
        },
      },
      {
        icon: announcement,
        title: "Announcement",
        time: "14:24",
        info: "Complete Tasks in the Welcome Back Challenge & Join the Competition.",
        details: {
          device: "Chrome 120",
          os: "Windows 11",
          location: "Dhaka, Bangladesh",
          ip: "103.97.34.8",
        },
      },
    ],
  },
  {
    date: "11th April 2024",
    notifications: [
      {
        icon: accountIcon,
        title: "Account Login",
        time: "14:24",
        info: "You have logged in your account from Windows Chrome 120.",
        details: {
          device: "Chrome 120",
          os: "Windows 11",
          location: "Dhaka, Bangladesh",
          ip: "103.97.34.8",
        },
      },
      {
        icon: announcement,
        title: "Announcement",
        time: "14:24",
        info: "Complete Tasks in the Welcome Back Challenge & Join the Competition.",
        details: {
          device: "Chrome 120",
          os: "Windows 11",
          location: "Dhaka, Bangladesh",
          ip: "103.97.34.8",
        },
      },
    ],
  },
];
const NotificationDropdown = ({
  isActive,
  setIsActive,
  children,
  userInfo,
}) => {
  const ref = useRef();

  const [showMore, setShowMore] = useState(null);
  let totalNotifications = 0;

  allNotifications.forEach((day) => {
    totalNotifications += day.notifications.length;
  });
  useOnClickOutside(ref, () => setIsActive(false));
  return (
    <div className={clsx(classes.dropdown)} ref={ref}>
      {children}
      <div className={clsx(classes.dropdownMain, isActive && classes.active)}>
        <div className={classes.header}>
          <Heading lg semiBold>
            Notifications {`(${totalNotifications})`}
          </Heading>
          <button className={classes.inbox}>
            <img src={inbox} alt="inbox" className={classes.inboxIcon} />
          </button>
        </div>
        <div className={classes.allNotifications}>
          {allNotifications.map((item, i) => (
            <div className={classes.notifications} key={i}>
              <Text secondary sm className={classes.date}>
                {item.date}
              </Text>
              {item.notifications.map((notification, id) => (
                <div className={classes.notification} key={id}>
                  <img
                    src={notification.icon}
                    alt="#"
                    className={classes.icon}
                  />
                  <div className={classes.notificationHeaderAndInfo}>
                    <div className={classes.notificationHeader}>
                      <Heading base>{notification.title}</Heading>
                      <Text xs secondary>
                        {notification.time}
                      </Text>
                    </div>
                    <Text sm secondary>
                      {notification.info}
                    </Text>
                    {showMore === i + 1 && (
                      <div className={classes.moreInfo}>
                        <Text sm secondary>
                          {notification.details.device}
                        </Text>{" "}
                        <Text sm secondary>
                          {notification.details.os}
                        </Text>{" "}
                        <Text sm secondary>
                          {notification.details.location}
                        </Text>{" "}
                        <Text sm secondary>
                          {notification.details.ip}
                        </Text>
                      </div>
                    )}
                    {showMore !== i + 1 && (
                      <Button
                        transparent
                        className={classes.moreButton}
                        onClick={() => setShowMore(i + 1)}
                      >
                        Read More <FaAngleRight />
                      </Button>
                    )}
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NotificationDropdown;
