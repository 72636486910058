import React, { useState, useRef } from "react";
import classes from "./Accounts.module.css";
import { Button, Heading, Input, Text } from "components/common";

import { avater } from "images";
import Calendar from "components/AccountSettings/DatePicker/DatePicker";
import Dropdown from "components/AccountSettings/Dropdown/Dropdown";

const Accounts = () => {
  const [name, setName] = useState("Frederick Johnson");
  const [isEditingName, setIsEditingName] = useState(false);

  const [userName, setUserName] = useState("");
  const [isEditingUserName, setIsEditingUserName] = useState(false);

  const [dob, setDob] = useState(null);
  const [isEditingDob, setIsEditingDob] = useState(false);

  const [gender, setGender] = useState("");
  const [isEditingGender, setIsEditingGender] = useState(false);

  const [image, setImage] = useState(null);
  const fileInputRef = useRef(null);

  const toggleEdit = (editSetter) => {
    editSetter((prev) => !prev);
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const renderEditableField = (
    label,
    value,
    setValue,
    isEditing,
    setIsEditing,
    placeholder = ""
  ) => (
    <div className={classes.infoContainer}>
      <Heading lg className={classes.label}>
        {label}
      </Heading>
      {isEditing ? (
        <Input
          placeholder={placeholder}
          value={value}
          setValue={setValue}
          className={classes.input}
        />
      ) : (
        <Text lg semiBold secondary className={classes.info}>
          {value ? value : "-"}
        </Text>
      )}
      <Button
        className={classes.button}
        onClick={() => toggleEdit(setIsEditing)}
        primitive900={value && !isEditing}
        primitive100={!value && !isEditing}
      >
        {isEditing ? "Save" : value ? "Change" : "Add"}
      </Button>
    </div>
  );

  const renderDatePickerField = (
    label,
    value,
    setValue,
    isEditing,
    setIsEditing
  ) => (
    <div className={classes.infoContainer}>
      <Heading lg className={classes.label}>
        {label}
      </Heading>
      {isEditing ? (
        <Calendar value={value} setValue={setValue} />
      ) : (
        <Text lg semiBold secondary className={classes.info}>
          {value ? value.toLocaleDateString() : "-"}
        </Text>
      )}
      <Button
        className={classes.button}
        onClick={() => toggleEdit(setIsEditing)}
        primitive900={value && !isEditing}
        primitive100={!value && !isEditing}
      >
        {isEditing ? "Save" : value ? "Change" : "Add"}
      </Button>
    </div>
  );

  const renderDropdownField = (
    label,
    value,
    setValue,
    isEditing,
    setIsEditing,
    options
  ) => (
    <div className={classes.infoContainer}>
      <Heading lg className={classes.label}>
        {label}
      </Heading>
      {isEditing ? (
        <Dropdown
          isActive={isEditing}
          setIsActive={setIsEditing}
          selectedValue={value}
          onSelect={(val) => setValue(val)}
          dropdownItems={options}
        />
      ) : (
        <Text lg semiBold secondary className={classes.info}>
          {value ? value : "-"}
        </Text>
      )}
      <Button
        className={classes.button}
        onClick={() => toggleEdit(setIsEditing)}
        primitive900={value && !isEditing}
        primitive100={!value && !isEditing}
      >
        {isEditing ? "Save" : value ? "Change" : "Add"}
      </Button>
    </div>
  );

  return (
    <main className={classes.wrapper}>
      {renderEditableField(
        "Name",
        name,
        setName,
        isEditingName,
        setIsEditingName
      )}
      {renderEditableField(
        "UserName",
        userName,
        setUserName,
        isEditingUserName,
        setIsEditingUserName,
        "Enter username"
      )}
      <div className={classes.infoContainer}>
        <Heading lg className={classes.label}>
          Image
        </Heading>
        <img
          src={image ? image : avater}
          alt="User"
          className={classes.userImage}
        />
        <input
          type="file"
          accept="image/*"
          onChange={handleImageUpload}
          ref={fileInputRef}
          className={classes.inputFile}
          style={{ display: "none" }}
        />
        <Button
          primitive900
          className={classes.button}
          onClick={() => fileInputRef.current.click()}
        >
          Change
        </Button>
      </div>
      {renderDatePickerField("DOB", dob, setDob, isEditingDob, setIsEditingDob)}
      {renderDropdownField(
        "Gender",
        gender,
        setGender,
        isEditingGender,
        setIsEditingGender,
        ["Male", "Female", "Other"]
      )}
      <div className={classes.infoContainer}>
        <Heading lg className={classes.label}>
          Member Since
        </Heading>
        <Text lg semiBold secondary className={classes.info}>
          Feb 2024
        </Text>
        <div></div>
      </div>
    </main>
  );
};

export default Accounts;
