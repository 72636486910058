import { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { FiSearch } from "react-icons/fi";
import { FaCheck } from "react-icons/fa6";
import { FaChevronDown, FaChevronUp } from "react-icons/fa6";
import classes from "./CurrencyDropdown.module.css";
import useOnClickOutside from "hooks";
import { Text } from "components/common";
const listItems = [
  {
    name: "USD",
    symbol: "$",
  },
  {
    name: "AUD",
    symbol: "$",
  },
  {
    name: "BZD",
    symbol: "BZ$",
  },
  {
    name: "BGN",
    symbol: "лв",
  },
  {
    name: "BRL",
    symbol: "R$",
  },
];
const CurrencyDropdown = ({
  isActive,
  setIsActive,
  selectedValue,
  onSelect,
  children,
  type2,
}) => {
  const [filteredItems, setFilteredItems] = useState(listItems);
  const inputRef = useRef();

  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    inputRef.current.focus();
  }, [isActive]);

  useEffect(() => {
    setFilteredItems(
      listItems?.filter(
        (el) =>
          el?.name.toLowerCase().includes(searchValue.toLowerCase()) ||
          el?.symbol.toLowerCase().includes(searchValue.toLowerCase())
      )
    );
  }, [searchValue]);
  const ref = useRef();

  useOnClickOutside(ref, () => setIsActive(false));
  return (
    <div className={clsx(classes.dropdown, type2 && classes.type2)} ref={ref}>
      {type2 ? (
        <div
          className={classes.labelContainer}
          onClick={() => setIsActive((prev) => !prev)}
        >
          {selectedValue ? (
            <Text lg textWhite>
              {selectedValue}
            </Text>
          ) : (
            <Text lg textWhite>
              All Currencies
            </Text>
          )}
          {isActive ? (
            <FaChevronUp className={classes.arrow} />
          ) : (
            <FaChevronDown className={classes.arrow} />
          )}
        </div>
      ) : (
        children
      )}
      <div className={clsx(classes.dropdownMain, isActive && classes.active)}>
        <div className={classes.searchContainer}>
          <FiSearch className={classes.searchIcon} />
          <input
            ref={inputRef}
            type="search"
            placeholder="Search"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </div>
        <div className={classes.list}>
          {filteredItems?.map((el, idx) => {
            return (
              <div
                key={"lang-list-item-" + idx}
                className={clsx(
                  classes.listItem,
                  selectedValue === el.name && classes.active
                )}
                onClick={() => {
                  setSearchValue("");
                  onSelect(el.name);
                  setIsActive(false);
                }}
              >
                <p className={classes.left}>{el.name}</p>

                {selectedValue === el.name ? (
                  <FaCheck className={classes.checkmark} />
                ) : (
                  <p className={classes.right}>{el.symbol}</p>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CurrencyDropdown;
