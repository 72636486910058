import React, { useState } from "react";
import classes from "./PaymentLink.module.css";
import { copyIcon2 } from "images";
import { Button, Input, Text } from "components/common";
import clsx from "clsx";
import { copyToClipboard } from "utils/utils";
import { LuMoveRight } from "react-icons/lu";
import CreatePaymentLinkModal from "Modals/CreatePaymentLinkModal/CreatePaymentLinkModal";
import PaymentLinkModal from "Modals/PaymentLinkModal/PaymentLinkModal";
import { useSelector } from "react-redux";

const PaymentLink = () => {
  const [searchValue, setSearchValue] = useState("");

  const [showCreateLinkModal, setShowCreateLinkModal] = useState(false);
  const [paymentLinkModal, setPaymentLinkModal] = useState(false);


  let data = useSelector((state) => state.requests.payments);
  console.log(data)
  // const data = [
  //   {
  //     paymentId: "632154987",
  //     orderId: "951623847159326784",
  //     price: "12 USD",
  //     amount: {
  //       sent: "16.15674 USD",
  //       received: "0.0046171 ETH",
  //     },
  //     currency: "USDT",
  //     paymentUrl: "https://www.example.com/payment/123456",
  //     createdAt: "21-6-2024, 11:58 PM",
  //     link: "#",
  //   },
  //   {
  //     paymentId: "632154987",
  //     orderId: "951623847159326784",
  //     price: "12 USD",
  //     amount: {
  //       sent: "16.15674 USD",
  //       received: "0.0046171 ETH",
  //     },
  //     currency: "USDT",
  //     paymentUrl: "https://www.example.com/payment/123456",
  //     createdAt: "21-6-2024, 11:58 PM",
  //     link: "#",
  //   },
  //   {
  //     paymentId: "632154987",
  //     orderId: "951623847159326784",
  //     price: "12 USD",
  //     amount: {
  //       sent: "16.15674 USD",
  //       received: "0.0046171 ETH",
  //     },
  //     currency: "USDT",
  //     paymentUrl: "https://www.example.com/payment/123456",
  //     createdAt: "21-6-2024, 11:58 PM",
  //     link: "#",
  //   },
  //   {
  //     paymentId: "632154987",
  //     orderId: "951623847159326784",
  //     price: "12 USD",
  //     amount: {
  //       sent: "16.15674 USD",
  //       received: "0.0046171 ETH",
  //     },
  //     currency: "USDT",
  //     paymentUrl: "https://www.example.com/payment/123456",
  //     createdAt: "21-6-2024, 11:58 PM",
  //     link: "#",
  //   },
  //   {
  //     paymentId: "632154987",
  //     orderId: "951623847159326784",
  //     price: "12 USD",
  //     amount: {
  //       sent: "16.15674 USD",
  //       received: "0.0046171 ETH",
  //     },
  //     currency: "USDT",
  //     paymentUrl: "https://www.example.com/payment/123456",
  //     createdAt: "21-6-2024, 11:58 PM",
  //     link: "#",
  //   },
  //   {
  //     paymentId: "632154987",
  //     orderId: "951623847159326784",
  //     price: "12 USD",
  //     amount: {
  //       sent: "16.15674 USD",
  //       received: "0.0046171 ETH",
  //     },
  //     currency: "USDT",
  //     paymentUrl: "https://www.example.com/payment/123456",
  //     createdAt: "21-6-2024, 11:58 PM",
  //     link: "#",
  //   },
  //   {
  //     paymentId: "632154987",
  //     orderId: "951623847159326784",
  //     price: "12 USD",
  //     amount: {
  //       sent: "16.15674 USD",
  //       received: "0.0046171 ETH",
  //     },
  //     currency: "USDT",
  //     paymentUrl: "https://www.example.com/payment/123456",
  //     createdAt: "21-6-2024, 11:58 PM",
  //     link: "#",
  //   },
  //   {
  //     paymentId: "632154987",
  //     orderId: "951623847159326784",
  //     price: "12 USD",
  //     amount: {
  //       sent: "16.15674 USD",
  //       received: "0.0046171 ETH",
  //     },
  //     currency: "USDT",
  //     paymentUrl: "https://www.example.com/payment/123456",
  //     createdAt: "21-6-2024, 11:58 PM",
  //     link: "#",
  //   },
  //   {
  //     paymentId: "632154987",
  //     orderId: "951623847159326784",
  //     price: "12 USD",
  //     amount: {
  //       sent: "16.15674 USD",
  //       received: "0.0046171 ETH",
  //     },
  //     currency: "USDT",
  //     paymentUrl: "https://www.example.com/payment/123456",
  //     createdAt: "21-6-2024, 11:58 PM",
  //     link: "#",
  //   },
  //   {
  //     paymentId: "632154987",
  //     orderId: "951623847159326784",
  //     price: "12 USD",
  //     amount: {
  //       sent: "16.15674 USD",
  //       received: "0.0046171 ETH",
  //     },
  //     currency: "USDT",
  //     paymentUrl: "https://www.example.com/payment/123456",
  //     createdAt: "21-6-2024, 11:58 PM",
  //     link: "#",
  //   },
  // ];

  const filterData = () => {
    return data?.filter((item) => {
      const searchMatch =
        item.id.toLowerCase().includes(searchValue.toLowerCase()) ||
        item.status.toLowerCase().includes(searchValue.toLowerCase());

      return searchMatch;
    });
  };

  const filteredData = filterData();

  const [copied, setCopied] = useState(false);

  return (
    <>
      <div className={clsx(classes.wrapper)}>
        <div className={classes.header}>
          <Button onClick={() => setShowCreateLinkModal(true)}>
            Create Payment Link
          </Button>
          <Input
            type="text"
            placeholder="Search"
            value={searchValue}
            setValue={setSearchValue}
            search
            className={classes.input}
          />
        </div>

        <div className={clsx(classes.tableWrapper, "overflow")}>
          <table className={classes.table}>
            <thead>
              <tr>
                <th>
                  <Text
                    textPlaceholer
                    sm
                    className={clsx(classes.heading, classes.firstHeading)}
                  >
                    Request ID
                  </Text>
                </th>
                <th>
                  <Text textPlaceholer sm className={clsx(classes.heading)}>
                    Status
                  </Text>
                </th>
                <th>
                  <Text textPlaceholer sm className={clsx(classes.heading)}>
                    Price
                  </Text>
                </th>
                <th>
                  <Text textPlaceholer sm className={clsx(classes.heading)}>
                    Currency
                  </Text>
                </th>
                <th>
                  <Text textPlaceholer sm className={clsx(classes.heading)}>
                    Payment URL
                  </Text>
                </th>
                <th>
                  <Text textPlaceholer sm className={clsx(classes.heading)}>
                    Created At
                  </Text>
                </th>
                <th className={clsx(classes.heading, classes.lastHeading)}></th>
              </tr>
            </thead>
            <tbody>
              {filteredData?.map((el, i) => (
                <tr key={i}>
                  <td>
                    <Text
                      primary
                      base
                      className={clsx(classes.item, classes.firstItem)}
                    >
                      {el.id}
                    </Text>
                  </td>
                  <td>
                    <Text primary base className={clsx(classes.item)}>
                      {el.status}
                    </Text>
                  </td>
                  <td>
                    <Text primary base className={clsx(classes.item)}>
                      {el.amount} USD
                    </Text>
                  </td>
                  <td>
                    <Text primary sm className={clsx(classes.item)}>
                      {el?.currency?.toUpperCase()}
                    </Text>
                  </td>
                  <td>
                    <div

                      className={classes.paymentUrl}>
                      <Text
                        primaryDefault
                        sm
                        className={clsx(classes.item, classes.url)}
                      >
                        {/* {el.paymentUrl} */}
                        {`https://triplepay.io/checkout/${el.id}`}
                      </Text>
                      <button onClick={() => {
                        copyToClipboard(`https://triplepay.io/checkout/${el.id}`);
                        setCopied(true);

                        setTimeout(() => {
                          setCopied(false);
                        }, 3000);
                      }}>
                        <img
                          // onClick={() => {
                          //   window.open(`https://triplepay.io/checkout/${el.id}`);
                          // }}
                          src={copyIcon2}
                          alt="#"
                          className={classes.copyIcon}
                        />
                      </button>
                    </div>
                  </td>
                  <td>
                    <Text primary base className={clsx(classes.item)}>
                      {new Date(el.created_at_timestamp * 1000).toLocaleString()}
                    </Text>
                  </td>
                  <td>
                    <button
                      onClick={() => {
                        // setPaymentLinkModal(true);
                        window.open(`https://triplepay.io/checkout/${el.id}`);
                      }}
                      className={clsx(classes.link, classes.item)}>
                      <LuMoveRight className={classes.arrowRight} />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <CreatePaymentLinkModal
        isActive={showCreateLinkModal}
        setShowCreateLinkModal={setShowCreateLinkModal}
        setPaymentLinkModal={setPaymentLinkModal}
        onClose={() => {
          setShowCreateLinkModal(false);
        }}
      />
      <PaymentLinkModal
        isActive={paymentLinkModal}
        onClose={() => {
          setPaymentLinkModal(false);
        }}
      />
    </>
  );
};

export default PaymentLink;
