import React from "react";
import classes from "./Footer.module.css";
import { mainLogo } from "../../../images";
import { Text } from "../../common";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className={classes.wrapper}>
      <Text textPlaceholer sm>
        Powered by
      </Text>
      <Link to="/">
        {" "}
        <img src={process.env.REACT_APP_LOGO_WORD_URL} alt="#" className={classes.logo} />
      </Link>
    </footer>
  );
};

export default Footer;
