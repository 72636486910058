import React, { useState } from "react";
import classes from "./SupportedCoin.module.css";
import {
  avaxLogo,
  bnbLogo,
  btcLogo,
  daiLogo,
  dashLogo,
  dogeLogo,
  ethLogo,
  ltcLogo,
  usdtLogo,
} from "images";

import { Heading, Input, Text } from "components/common";
import clsx from "clsx";
import { FaCircleCheck } from "react-icons/fa6";
import { formatter } from "utils/utils";

const SupportedCoin = ({ currencies, className }) => {
  const [activeCoin, setActiveCoin] = useState("USDT");
  const [searchValue, setSearchValue] = useState("");

  // const filteredCoins = currencies?.filter((coin) =>
  //   currencies?.name.toLowerCase().includes(searchValue.toLowerCase())
  // );

  return (
    <div className={clsx(classes.wrapper, className)}>
      <Input
        search
        value={searchValue}
        setValue={setSearchValue}
        placeholder="Search"
      />
      <div className={clsx(classes.coins, "overflow")}>
        {currencies.map((coin, i) => (
          <div
            className={clsx(
              classes.coin,
              activeCoin === coin.name && classes.activeCoin
            )}
            onClick={() => {
              setActiveCoin(coin.name);
            }}
            key={i}
          >
            <img src={coin.logo} alt="Coin" className={classes.coinImg} />
            <div className={classes.nameAndPrice}>
              <Heading semiBold lg>
                {coin.name}
              </Heading>
              <Text textTeritary sm semibold>
                {/* {formatter.format(coin.price)} */}
              </Text>
            </div>

            {activeCoin === coin.name && (
              <FaCircleCheck className={classes.checkMark} />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SupportedCoin;
