import React, { useState } from "react";
import classes from "./AccountSettingsLayout.module.css";
import Sidebar from "components/AccountSettings/Sidebar/Sidebar";
import { Outlet } from "react-router-dom";
import clsx from "clsx";
import Navbar from "components/AccountSettings/Navbar/Navbar";
import { Heading } from "components/common";

const AccountSettingsLayout = () => {
  const [activeLink, setActiveLink] = useState("Accounts");
  return (
    <main>
      <Navbar />
      <div className={clsx("container", classes.container)}>
        <Sidebar activeLink={activeLink} setActiveLink={setActiveLink} />

        <div className={classes.outlet}>
          <Heading xl2 className={classes.heading}>
            {activeLink}
          </Heading>
          <Outlet />
        </div>
      </div>
    </main>
  );
};

export default AccountSettingsLayout;
