import React, { useState } from "react";
import classes from "./BusinessWallet.module.css";
import MerchantInfo from "components/Wallet/BusinessWallet/MerchantInfo/MerchantInfo";
import MyMerchants from "components/Wallet/BusinessWallet/MyMerchants/MyMerchants";
import PaymentHistory from "components/Wallet/BusinessWallet/PaymentHistory/PaymentHistory";
import Transaction from "components/Wallet/BusinessWallet/Transaction/Transaction";
import PaymentLink from "components/Wallet/BusinessWallet/PaymentLink/PaymentLink";
import {
  historyIcon,
  historyIcon2,
  linkIcon,
  linkIcon2,
  transactionIcon,
  transactionIcon2,
} from "images";
import { Heading } from "components/common";
import clsx from "clsx";

const BusinessWallet = () => {
  const tabs = [
    { icons: [transactionIcon, transactionIcon2], name: "Transaction" },
    { icons: [historyIcon, historyIcon2], name: "Payment History" },

    { icons: [linkIcon, linkIcon2], name: "Payment Link" },
  ];

  const [activeTab, setActiveTab] = useState(localStorage.getItem("activeTab") || "Transaction");

  // Get the active tab's icon and name
  const activeTabData = tabs.find((tab) => tab.name === activeTab) || tabs[0];

  return (
    <section className={classes.wrapper}>
      <div className={classes.container}>
        <MerchantInfo />
        <MyMerchants />
      </div>
      <div className={classes.tableContainer}>
        <div className={classes.tabs}>
          {tabs.map((el, i) => (
            <div
              className={clsx(
                activeTab === el.name
                  ? clsx(classes.activeTab, classes.tab)
                  : classes.tab
              )}
              key={i}
              onClick={() => {
                setActiveTab(el.name)
                localStorage.setItem("activeTab", el.name)
              }}
            >
              <img
                src={activeTab === el.name ? el.icons[0] : el.icons[1]}
                alt={el.name}
                className={classes.icon}
              />
              <Heading lg semiBold className={clsx(classes.name)}>
                {el.name}
              </Heading>
            </div>
          ))}
        </div>

        {activeTab === "Transaction" && <Transaction />}
        {activeTab === "Payment History" && <PaymentHistory />}
        {activeTab === "Payment Link" && <PaymentLink />}
      </div>
    </section>
  );
};

export default BusinessWallet;
