import Modal from "components/common/Modal/Modal";
import React from "react";
import classes from "./ShowQrCode.module.css";
import { Button, Heading } from "components/common";
import { IoClose } from "react-icons/io5";
import { qrCode } from "images";
import QRCode from "react-qr-code";

const ShowQrCode = ({ isActive, onClose }) => {
  return (
    <Modal isActive={isActive} onClose={onClose} className={classes.modal}>
      <div className={classes.wrapper}>
        <div className={classes.header}>
          <Heading primary lg>
            QR Code
          </Heading>
          <button className={classes.closeButton} onClick={onClose}>
            <IoClose className={classes.closeIcon} />
          </button>
        </div>
        <div className={classes.qrCodeWrapper}>
          <QRCode
            size={256}
            style={{
              height: "auto",
              maxWidth: "100%",
              width: "100%",
              borderRadius: "6px",
            }}
            value={""}
            viewBox={`0 0 256 256`}
          />
          {/* <img src={qrCode} alt="#" className={classes.qrCode} />{" "} */}
          <Button primitive800>Share QR Code</Button>
        </div>
        <div className={classes.buttonContainer}>
          <Button primitive800>Close</Button>
          <Button>Generate New Address</Button>
        </div>
      </div>
      ;
    </Modal>
  );
};

export default ShowQrCode;
