import React, { useState } from "react";
import classes from "./CreatePaymentLinkModal.module.css";
import Modal from "components/common/Modal/Modal";
import { Button, Heading, Input, Text } from "components/common";
import { IoClose } from "react-icons/io5";
import clsx from "clsx";
import { IoInformationCircleOutline } from "react-icons/io5";

import { handleKeyDown } from "hooks";
import CurrencyDropdown from "components/Wallet/CurrencyDropdown/CurrencyDropdown";
import Checkbox from "components/common/CheckBox/CheckBox";
import axios from "axios";
import Cookies from "js-cookie";

const CreatePaymentLinkModal = ({
  isActive,
  onClose,
  setShowCreateLinkModal,
  setPaymentLinkModal,
}) => {
  const [showCurrencyDropdown, setShowCurrencyDropdown] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [price, setPrice] = useState("");
  const [description, setDescription] = useState("");
  const [orderId, setOrderId] = useState("");
  const [fixedRate, setFixedRate] = useState(false);
  const [freePaid, setFreePaid] = useState(false);

  async function create_payment() {
    let api = process.env.REACT_APP_API_URL + "/payments/request/create";

    let body = {
      currency: selectedCurrency.toLowerCase(),
      amount: Number(price),
    }
    try {
      let { data, status } = await axios.post(api, body, {
        headers: {
          "Authorization": `Bearer ${Cookies.get("token")}`
        }
      });
    } catch (e) {
      console.log(e)
    }
  }
  return (
    <>
      <Modal isActive={isActive} onClose={onClose}>
        <div className={classes.wrapper}>
          <div className={classes.header}>
            <Heading primary lg>
              Create Payment Link
            </Heading>
            <button className={classes.closeButton} onClick={onClose}>
              <IoClose className={classes.closeIcon} />
            </button>
          </div>
          <div className={classes.selectContainer}>
            <Heading xl className={clsx(classes.label, classes.payCurrency)}>
              Pay currency
            </Heading>
            <div className={classes.dropdownContainer}>
              <CurrencyDropdown
                type2
                isActive={showCurrencyDropdown}
                setIsActive={setShowCurrencyDropdown}
                selectedValue={selectedCurrency}
                onSelect={(val) => setSelectedCurrency(val)}
              >
                <Text lg textWhite>
                  All Currencies
                </Text>
              </CurrencyDropdown>
              {!selectedCurrency && (
                <Text primary sm className={classes.info}>
                  Client can choose any supported crypto for paymnent
                </Text>
              )}
            </div>
            {/* enter Price */}
            <Heading xl className={classes.label}>
              Amount
            </Heading>
            <div className={classes.priceContainer}>
              <Input
                value={price}
                setValue={setPrice}
                type="number"
                onKeyDown={handleKeyDown}
                className={clsx(classes.input, classes.priceInput)}
                placeholder="0.00"
              />
              <Text semiBold textWhite base>
                USD
              </Text>
            </div>
            {/* Type */}
            <Heading xl className={clsx(classes.label, classes.type)}>
              Type
            </Heading>
            <div className={classes.typeContainer}>
              <div className={classes.fixedRate}>
                <Checkbox checked={fixedRate} setChecked={setFixedRate} />
                <Text primary base semibold>
                  Fixed Rate
                </Text>{" "}
                <IoInformationCircleOutline className={classes.infoIcon} />
              </div>
              <div className={classes.freePaid}>
                <Checkbox checked={freePaid} setChecked={setFreePaid} />
                <Text primary base semibold>
                  Free paid by user
                </Text>{" "}
                <IoInformationCircleOutline className={classes.infoIcon} />
              </div>
            </div>
          </div>

          <div className={classes.buttonContainer}>
            <Button primitive800 onClick={onClose}>
              Cancel
            </Button>{" "}
            <Button
              onClick={() => {
                create_payment();
                // setShowCreateLinkModal(false);
                // setPaymentLinkModal(true);
              }}
            >
              Create Link
            </Button>
          </div>
        </div>
        ;
      </Modal>{" "}
    </>
  );
};

export default CreatePaymentLinkModal;
