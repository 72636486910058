import React, { useEffect, useState } from "react";
import classes from "./PayNow.module.css";
import { Button, Text } from "components/common";
import { brandlogo, copyIcon, smallQrCode } from "images";
import clsx from "clsx";
import { copyToClipboard } from "utils/utils";
import useWebSocket, { ReadyState } from 'react-use-websocket';

import {
  avaxLogo,
  bnbLogo,
  btcLogo,
  daiLogo,
  dashLogo,
  dogeLogo,
  ethLogo,
  usdtLogo,
} from "images";
import Marquee from "react-fast-marquee";
import ShowQrCode from "Modals/ShowQrCode/ShowQrCode";
import axios from "axios";
import { GoInfo } from "react-icons/go";
import { toast } from "react-toastify";

const PayNow = ({
  generateAddress,
  setCheckoutData,
  checkoutData,
  currencies,
  setStep,
  networks,
  setRefresh,
  paymentDetails,
}) => {
  let first_address = checkoutData?.addresses[0].address;
  let first_protocol = checkoutData?.addresses[0].protocol;

  const [showAllCoins, setShowAllCoins] = useState(false);
  const [showQrCodeModal, setShowQrCodeModal] = useState(false);
  const [allSupportedCurrencies, setAllSupportedCurrencies] = useState([]);
  const [supportedCurrencies, setSupportedCurrencies] = useState([]);
  const [copied, setCopied] = useState(false);
  const [amountCopied, setAmountCopied] = useState(false);
  const [isCalculatingRate, setIsCalculatingRate] = useState(false);
  const [paymentAddress, setPaymentAddress] = useState(null);

  const [generating, setGenerating] = useState(false);

  const [selectedBlockChain, setSelectedBlockChain] = useState({
    name: "Select Blockchain"
  });
  const [selectedToken, setSelectedToken] = useState(null);



  const socketUrl = "wss://api.triplepay.io/v1/public/subscribe";
  let subscription_message = JSON.stringify({
    payment_id: checkoutData?.payment_id
  });

  const {
    sendMessage,
    sendJsonMessage,
    lastMessage,
    lastJsonMessage,
    readyState,
    getWebSocket,
  } = useWebSocket(socketUrl, {
    onOpen: () => {
      sendMessage(subscription_message);
      //
    },

    onMessage: (event) => {
      let data = JSON.parse(event.data);
      console.log(data)

      if (data.transaction_hash) {
        // its a new transaction
        let amount = data.formatted_amount;

        if (!amount) {
          return
        }
        setRefresh((prev) => {
          prev += 1
        })
        toast.info(`
          Pending Payment of ${data.amount} detected from ${data.network_id} Network `)
      }

      if (data.amount_received) {
        // info its a payment update
        toast.info("payment updated")
        setCheckoutData(data)
      }





    },
    //Will attempt to reconnect on all close events, such as server shutting down
    shouldReconnect: (closeEvent) => true,

    onError: (event) => {
      alert("Error");
    },
  });






  useEffect(() => {
    // setSelectedBlockChain(networks?.find((a) => a?.protocol == first_protocol));
    // sort_supported(selectedBlockChain?.id);
  }, [networks]);
  const toggleShowAllCoins = () => {
    setShowAllCoins((prevState) => !prevState);
  };
  async function get_supported_methods() {
    try {
      let api =
        process.env.REACT_APP_API_URL +
        "/payments/get_supported_tokens/" +
        checkoutData.payment_id;
      console.log(api);
      let { data } = await axios.get(api);

      console.log(data);
      let arr = [];

      for (let i = 0; i < currencies.length; i++) {
        // let obj = {};
        // obj.logo = data[i].logo;
        // arr.push(obj);
        let currency = currencies[i];
        for (let j = 0; j < data.length; j++) {
          arr.push(currency);
          break;
        }
      }
      // setSupportedCurrencies(arr);
      setAllSupportedCurrencies(arr);
    } catch (e) {
      console.log(e);
    }
  }

  function truncateMiddle(text, maxLength) {
    console.log(text, maxLength)
    if (!text) {
      return "";
    }
    if (text?.length <= maxLength) {
      return text;
    }
    const half = Math.floor(maxLength / 2) - 1;
    return text?.slice(0, half) + '...' + text?.slice(-half);
  }
  async function sort_supported(network_id) {
    let new_supported = [];
    let seen = new Set();

    for (let i = 0; i < allSupportedCurrencies.length; i++) {
      let currency = allSupportedCurrencies[i];

      for (let j = 0; j < currency.network_config.length; j++) {
        if (currency.network_config[j].network_id == network_id) {
          // Check if the currency has already been added to the new_supported array
          if (!seen.has(currency)) {
            new_supported.push(currency);
            seen.add(currency);
          }
          break;
        }
      }
    }

    setSupportedCurrencies(new_supported);
    return new_supported;
  }

  useEffect(() => {
    sort_supported(selectedBlockChain?.id);
  }, [allSupportedCurrencies]);

  useEffect(() => {
    get_supported_methods();
  }, [currencies, networks]);

  let remaining_amount = Number(
    checkoutData?.amount - checkoutData?.amount_received
  );

  return (
    <div className={classes.mainWrapper}>
      <div className={clsx(classes.wrapper, "overflow")}>
        <div className={classes.container}>
          <div className={classes.infoContainer}>
            <Text sm primaryDefault semiBold>
              Select a payment method to pay
            </Text>
          </div>
          {/* Blockchain */}
          <div className={classes.supportedCoin}>
            <div className={classes.header}>
              <Text secondary semiBold base className={classes.heading}>
                Network: {" "}
                {selectedBlockChain ? (
                  <span className={classes.selectedItem}>
                    {selectedBlockChain.name}
                  </span>
                ) : (
                  ""
                )}
              </Text>
            </div>
            <div className={classes.coinsWrapper}>
              {1 != 2 ? (
                <div className={classes.allCoins}>
                  {networks?.map((coin, i) => (
                    <img
                      src={coin.logo}
                      alt="#"
                      className={clsx(
                        classes.coinLogo,
                        selectedBlockChain?.name === coin?.name &&
                        classes.selected
                      )}
                      key={i}
                      onClick={async () => {
                        setSelectedBlockChain(coin);

                        let address = checkoutData?.addresses.find(
                          (a) => a.protocol == coin.protocol
                        );
                        if (address) {
                          setPaymentAddress(address.address);
                        } else {
                          setGenerating(true);
                          let data = await generateAddress(checkoutData?.payment_id, coin.protocol);
                          let address = data?.addresses.find(
                            (a) => a.protocol == coin.protocol
                          );

                          if (address) {
                            setPaymentAddress(address.address);
                          }

                          setGenerating(false);

                        }


                        let supported = await sort_supported(coin.id);
                        let first_coin = supported[0];
                        if (first_coin) {
                          setSelectedToken(first_coin);
                        } else {
                          // alert("No supported token found");
                          setSelectedToken(null);
                        }
                      }}
                    />
                  ))}
                </div>
              ) : (
                <Marquee autoFill={true} speed={40} pauseOnHover={true}>
                  <div className={classes.allCoins}>
                    {supportedCurrencies.map((coin, i) => (
                      <img
                        src={coin.logo}
                        alt="#"
                        key={i}
                        className={clsx(
                          classes.coinLogo,

                          selectedBlockChain === coin.name && classes.selected
                        )}
                        onClick={() => setSelectedBlockChain(coin.name)}
                      />
                    ))}
                  </div>
                </Marquee>
              )}
            </div>
          </div>
          {selectedToken && <div className={classes.buttonContainer}>
            <div className={classes.supportedCoin}>
              <div className={classes.header}>
                <Text secondary semiBold base className={classes.heading}>
                  Tokens
                  {selectedToken ? (
                    <span className={classes.selectedItem}>
                      {selectedToken.name}
                    </span>
                  ) : (
                    ""
                  )}
                </Text>
                {showAllCoins && (
                  <Button
                    onClick={toggleShowAllCoins}
                    className={classes.lessButton}
                  >
                    Less
                  </Button>
                )}
              </div>
              <div className={classes.coinsWrapper}>
                {showAllCoins ? (
                  <div className={classes.allCoins}>
                    {supportedCurrencies.map((coin, i) => (
                      <img
                        onClick={() => {
                          setSelectedToken(coin);
                          toggleShowAllCoins();
                        }}
                        src={coin.logo}
                        alt="#"
                        className={classes.coinLogo}
                        key={i}
                      />
                    ))}
                  </div>
                ) : (
                  <Marquee
                    autoFill={supportedCurrencies.length > 3}
                    speed={90}
                    pauseOnHover={true}
                  >
                    <div className={classes.allCoins}>
                      {supportedCurrencies.map((coin, i) => (
                        <img
                          src={coin.logo}
                          onClick={() => {
                            setSelectedToken(coin);
                            // toggleShowAllCoins();
                          }}
                          alt="#"
                          className={classes.coinLogo}
                          key={i}
                        />
                      ))}
                    </div>
                  </Marquee>
                )}
                {!showAllCoins && (
                  <Button
                    onClick={toggleShowAllCoins}
                    className={classes.allButton}
                  >
                    All
                  </Button>
                )}
              </div>
            </div>

            {/* <Button
            className={clsx(classes.qrCodeButton, classes.button)}
            wFull
            onClick={() => setStep((prev) => prev + 1)}
          >
            <Text lg primary semiBold>
              Supported Blockchains
            </Text>
            <img
              src={
                "https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png"
              }
              alt="#"
              className={classes.logo}
            />
          </Button> */}
          </div>}
          {/* <Text sm textPlaceholder textCenter className={classes.or}>
          or
        </Text> */}

          {/*  */}
          {/*  */}
          {/*  */}
          {/*  */}
          {/*  */}
          {/*  */}


          {selectedToken && <div className={classes.buttonContainer}>
            <div className={classes.addressAndQrCode}>
              <Button
                onClick={() => {
                  copyToClipboard(first_address);
                  setCopied(true);
                  setTimeout(() => {
                    setCopied(false);
                  }, 1200);
                }}
                className={clsx(classes.button, classes.copyButton)}
                wFull
              >
                <Text lg textWhite semiBold className={classes.address}>
                  {copied ? "Copied" : generating ? "generating....." : paymentAddress ? truncateMiddle(paymentAddress, 20) : "No Address Found"}
                </Text>
                <button
                  className={classes.copyContainer}
                  onClick={() => {
                    copyToClipboard(first_address);
                    setCopied(true);
                    setTimeout(() => {
                      setCopied(false);
                    }, 1200);
                  }}
                >
                  <img src={copyIcon} alt="#" className={classes.logo} />
                </button>
              </Button>
              <Button
                className={clsx(classes.qrCodeButton, classes.button)}
                wFull
                onClick={() => setShowQrCodeModal(true)}
              >
                <img src={smallQrCode} alt="#" width={20} />
              </Button>
            </div>
            {remaining_amount > 0 && <Button
              onClick={() => {
                copyToClipboard(checkoutData?.amount);
                setAmountCopied(true);
                setTimeout(() => {
                  setAmountCopied(false);
                }, 1200);
              }}
              className={clsx(classes.button, classes.copyButton)}
              wFull
            >
              <Text lg textWhite semiBold className={classes.address}>
                {isCalculatingRate
                  ? "Calculating rate ..."
                  : amountCopied
                    ? "Copied"
                    : remaining_amount > 0
                      ? (remaining_amount) + " " + selectedToken?.symbol?.toUpperCase()
                      : "Paid"}
              </Text>
              <button
                className={classes.copyContainer}
                onClick={() => {
                  copyToClipboard(first_address);
                  setAmountCopied(true);
                  setTimeout(() => {
                    setAmountCopied(false);
                  }, 1200);
                }}
              >
                <img src={copyIcon} alt="#" className={classes.logo} />
              </button>
            </Button>}
            {/* <Button
            className={clsx(classes.qrCodeButton, classes.button)}
            wFull
            onClick={() => setShowQrCodeModal(true)}
          >
            <Text lg primary semiBold>
              Show QR Code
            </Text>
            <img src={smallQrCode} alt="#" width={20} />
          </Button> */}
            <Button className={classes.button} wFull>
              <Text lg textWhite semiBold>
                Pay in Wallet
              </Text>
              <img
                src={process.env.REACT_APP_LOGO_URL}
                alt="#"
                className={classes.logo}
              />
            </Button>
          </div>
          }


          <div className={classes.bottombarAmountContainer}>
            {checkoutData?.amount && <div className={clsx(classes.bottomBar, classes.amountWrapper)}>
              <Text base semiBold>
                Amount
              </Text>{" "}
              <Text base semiBold className={classes.value}>
                {Math.trunc(checkoutData?.amount * 100) / 100} USD
              </Text>
            </div>}
            {/* <div className={clsx(classes.bottomBar)}>
              <Text base semiBold>
                Token Amount
              </Text>{" "}
              <Text base semiBold className={classes.value}>
                0.00 ETH
              </Text>
            </div> */}


            <div className={clsx(classes.bottomBar)}>
              <Text base semiBold>
                Amount Paid
              </Text>{" "}
              <Text
                base
                semiBold
                className={clsx(
                  classes.completed,
                  classes.value
                )}
              >
                {/* {Number(checkoutData?.amount_received).toFixed(4)} USD */}
                {Math.trunc(checkoutData?.amount_received * 100) / 100} USD
              </Text>
            </div>

            <div className={clsx(classes.bottomBar)}>
              <Text base semiBold>
                Status
              </Text>{" "}
              <Text
                base
                semiBold
                className={clsx(
                  checkoutData?.status?.toLowerCase() == "PartiallyPaid".toLowerCase() &&
                  classes.partial,
                  checkoutData?.status?.toLowerCase() == "waiting".toLowerCase() &&
                  classes.partial,
                  checkoutData?.status?.toLowerCase() == "completed" &&
                  classes.completed,
                  classes.value
                )}
              >
                {(checkoutData?.status)}
              </Text>
            </div>
            {paymentDetails?.linked_request && (
              <div className={clsx(classes.bottomBar)}>
                <Text base semiBold>
                  Linked Request
                </Text>{" "}
                <Text base semiBold className={classes.value}>
                  {paymentDetails?.linked_request}
                </Text>
              </div>
            )}
          </div>
          <a
            href="#/"
            target="_blank"
            rel="noreferrer"
            className={classes.learnMore}
          >
            <GoInfo className={classes.infoIcon} />
            <Text primaryDefault sm>
              Learn how to pay
            </Text>
          </a>
        </div>
      </div>

      {showQrCodeModal && (
        <ShowQrCode
          isActive={showQrCodeModal}
          onClose={() => setShowQrCodeModal(false)}
        />
      )}
    </div>
  );
};

export default PayNow;
