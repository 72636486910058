import React, { useState } from "react";
import classes from "./ChangeEmailModal.module.css";
import Modal from "components/common/Modal/Modal";
import { Button, Heading, Input } from "components/common";
import { IoClose } from "react-icons/io5";

import Verification from "pages/Authentication/Verification/Verification";
import Header from "components/Auth/Header/Header";

const ChangeEmailModal = ({ isActive, onClose, email, setEmail }) => {
  const [step, setStep] = useState(1);

  return (
    <Modal
      isActive={isActive}
      onClose={() => {
        onClose();
        setStep((prev) => prev - 1);
      }}
    >
      <div className={classes.wrapper}>
        <div className={classes.header}>
          <Heading primary lg>
            Change Email
          </Heading>
          <button
            className={classes.closeButton}
            onClick={() => {
              onClose();
              setStep((prev) => prev - 1);
            }}
          >
            <IoClose className={classes.closeIcon} />
          </button>
        </div>

        {step === 1 && (
          <Verification
            noResend
            heading="Verify Your Identity"
            info="Enter your verification code. We sent a 6 digit verification code to your email."
            setStep={setStep}
          />
        )}
        {step === 2 && (
          <>
            <div className={classes.inputContainer}>
              <Header
                heading="Set new Email"
                info="Enter a new email to set this email."
              ></Header>
              <Input
                className={classes.input}
                type="email"
                value={email}
                setValue={setEmail}
                placeholder="Enter new email"
              />
            </div>
            <div className={classes.buttonContainer}>
              <Button
                primitive800
                onClick={() => {
                  onClose();
                  setStep((prev) => prev - 1);
                }}
              >
                Cancel
              </Button>{" "}
              <Button
                onClick={() => {
                  setStep((prev) => prev + 1);
                }}
              >
                Save Email
              </Button>
            </div>
          </>
        )}

        {step === 3 && (
          <Verification
            noResend
            heading="Verify New Email"
            info="Enter your verification code. We sent a 6 digit verification code to your new email."
            onCancel={onClose}
            setStep={setStep}
          />
        )}
      </div>
      ;
    </Modal>
  );
};

export default ChangeEmailModal;
