import React from "react";
import classes from "./CheckoutLayout.module.css";
import { Outlet } from "react-router-dom";
import Footer from "components/Checkout/Footer/Footer";
import Navbar from "components/Checkout/Navbar/Navbar";

const CheckoutLayout = () => {
  return (
    <main className={classes.wrapper}>
      <Navbar />
      <div className={classes.outlet}>
        <Outlet />
      </div>
      <Footer />
    </main>
  );
};

export default CheckoutLayout;
