import React, { useState } from "react";
import classes from "./ContactPage.module.css";

import Dropdown from "./Dropdown/Dropdown";

import { Button, Heading, Input } from "components/common";
import ImageSlider from "components/Contact/ImageSlider/ImageSlider";
import { receiveCrypto } from "images";
import axios from "axios";
import { toast } from "react-toastify";

const countries = ["USA", "UK"];
const businessTypes = ["General", "Support", "Sales", "Technical", "Other"];
const ContactPage = () => {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [showCountryDropdown, setShowCountryDropdown] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [showBusinessTypeDropdown, setShowBusinessTypeDropdown] = useState("");
  const [selectedBusinessType, setSelectedBusinessType] = useState("");


  const [isSubmitted, setIsSubmitted] = useState(false);

  const images = [receiveCrypto, receiveCrypto, receiveCrypto];
  function clear_form() {
    setFullName("");
    setEmail("");
    setMessage("");
    setPhoneNumber("");
    setCompanyName("");
    setSelectedCountry("");
    setSelectedBusinessType("");
  }
  async function handleSubmit() {
    try {
      let api = process.env.REACT_APP_API_URL + "/public/contact";
      let body = {
        subject: "Contact Form",
        name: fullName,
        company: companyName,
        email: email,
        enquiry_type: selectedBusinessType,
        message: message,
      };
      let { data, status } = await axios.post(api, body);
      if (status == 200) {
        console.log(data);
        toast.info("Message sent successfully", {
          position: "top-center",
        });
      }

      setIsSubmitted(true);
      console.log("submitted");
      clear_form();
      setTimeout(() => {
        setIsSubmitted(false);
      }, 15000);
    } catch (e) {
      console.log(e);
    }
  }


  return (
    <div className={classes.wrapper}>
      <Heading xl5 font600 className={classes.heading}>
        Drop a message for us, we’ll get back to you
      </Heading>{" "}
      <div className={classes.container}>
        <div className={classes.inputWrapper}>
          <Input
            type="text"
            value={fullName}
            setValue={setFullName}
            placeholder="Full name"
            className={classes.input}
          />{" "}
          <Input
            type="email"
            value={email}
            setValue={setEmail}
            placeholder="Email"
            className={classes.input}
          />
          {/* <div className={classes.inputContainer}>
            <Dropdown
              items={countries}
              isActive={showCountryDropdown}
              setIsActive={setShowCountryDropdown}
              selectedValue={selectedCountry}
              onSelect={(val) => setSelectedCountry(val)}
              placeholder="Country"
            />
            <Input
              type="tel"
              value={phoneNumber}
              setValue={setPhoneNumber}
              placeholder="Phone Number"
              className={classes.input}
            />
          </div> */}
          <div className={classes.inputContainer}>
            <Input
              type="text"
              value={companyName}
              setValue={setCompanyName}
              placeholder="Company"
              className={classes.input}
            />
            <Dropdown
              items={businessTypes}
              isActive={showBusinessTypeDropdown}
              setIsActive={setShowBusinessTypeDropdown}
              selectedValue={selectedBusinessType}
              onSelect={(val) => setSelectedBusinessType(val)}
              placeholder="Enquiry Type"
            />
          </div>
          <textarea
            className={classes.message}
            name="message"
            id="message"
            rows={4}
            cols={3}
            placeholder="Detail message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>
          <Button primitive100 wFull onClick={() => {
            if (!isSubmitted) {
              handleSubmit();
            }
            if (isSubmitted) {
              console.log("submitted");
              // window.location.href = "https://t.me/cryptociva" //
              window.open("https://t.me/cryptociva", "_blank")
              setIsSubmitted(false);
            }
          }}>
            {isSubmitted ? "Message on Telegram" : "Submit"}{" "}
          </Button>
        </div>{" "}
        <ImageSlider images={images} />
      </div>{" "}
    </div>
  );
};

export default ContactPage;
