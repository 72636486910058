import React from "react";
import classes from "./WalletLayout.module.css";
import { Outlet } from "react-router-dom";
import Navbar from "components/Wallet/Navbar/Navbar";
import clsx from "clsx";

const WalletLayout = () => {
  return (
    <main>
      <Navbar />
      <div className={clsx(classes.outlet, "container")}>
        <Outlet />
      </div>
    </main>
  );
};

export default WalletLayout;
