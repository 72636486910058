import React from "react";
import classes from "./TransactionDetailsModal.module.css";
import Modal from "components/common/Modal/Modal";
import { Heading, Text } from "components/common";
import { IoClose } from "react-icons/io5";

import clsx from "clsx";

const TransactionDetailsModal = ({ isActive, onClose, details }) => {
  return (
    <Modal isActive={isActive} onClose={onClose}>
      <div className={classes.wrapper}>
        <div className={classes.header}>
          <Heading primary lg>
            Transaction Details
          </Heading>
          <button className={classes.closeButton} onClick={onClose}>
            <IoClose className={classes.closeIcon} />
          </button>
        </div>

        <div className={classes.details}>
          {details.map((el, i) => (
            <div className={classes.spaceBetween} key={i}>
              <Text base primary>
                {el.key}
              </Text>{" "}
              <Text
                base
                textWhite
                className={clsx(
                  classes.value,

                  el.value.toLowerCase() === "completed" && classes.completed,
                  el.value.toLowerCase() === "pending" && classes.pending,
                  el.value.toLowerCase() === "failed" && classes.failed
                )}
              >
                {el.value}
              </Text>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default TransactionDetailsModal;
