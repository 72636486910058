import React from "react";
import classes from "./MyRefferel.module.css";

import { Heading, Text } from "components/common";
import clsx from "clsx";

const MyRefferel = () => {
  const data = [
    {
      id: "123456",
      refferedEmail: "user@example.com",
      finishedTransaction: "3694.92 USD",
    },
    {
      id: "123456",
      refferedEmail: "user@example.com",
      finishedTransaction: "3694.92 USD",
    },
    {
      id: "123456",
      refferedEmail: "user@example.com",
      finishedTransaction: "3694.92 USD",
    },
    {
      id: "123456",
      refferedEmail: "user@example.com",
      finishedTransaction: "3694.92 USD",
    },
    {
      id: "123456",
      refferedEmail: "user@example.com",
      finishedTransaction: "3694.92 USD",
    },
    {
      id: "123456",
      refferedEmail: "user@example.com",
      finishedTransaction: "3694.92 USD",
    },
    {
      id: "123456",
      refferedEmail: "user@example.com",
      finishedTransaction: "3694.92 USD",
    },
    {
      id: "123456",
      refferedEmail: "user@example.com",
      finishedTransaction: "3694.92 USD",
    },
    {
      id: "123456",
      refferedEmail: "user@example.com",
      finishedTransaction: "3694.92 USD",
    },
    {
      id: "123456",
      refferedEmail: "user@example.com",
      finishedTransaction: "3694.92 USD",
    },
    {
      id: "123456",
      refferedEmail: "user@example.com",
      finishedTransaction: "3694.92 USD",
    },
    {
      id: "123456",
      refferedEmail: "user@example.com",
      finishedTransaction: "3694.92 USD",
    },
    {
      id: "123456",
      refferedEmail: "user@example.com",
      finishedTransaction: "3694.92 USD",
    },
    {
      id: "123456",
      refferedEmail: "user@example.com",
      finishedTransaction: "3694.92 USD",
    },
    {
      id: "123456",
      refferedEmail: "user@example.com",
      finishedTransaction: "3694.92 USD",
    },
    {
      id: "123456",
      refferedEmail: "user@example.com",
      finishedTransaction: "3694.92 USD",
    },
    {
      id: "123456",
      refferedEmail: "user@example.com",
      finishedTransaction: "3694.92 USD",
    },
    {
      id: "123456",
      refferedEmail: "user@example.com",
      finishedTransaction: "3694.92 USD",
    },
    {
      id: "123456",
      refferedEmail: "user@example.com",
      finishedTransaction: "3694.92 USD",
    },
    {
      id: "123456",
      refferedEmail: "user@example.com",
      finishedTransaction: "3694.92 USD",
    },
    {
      id: "123456",
      refferedEmail: "user@example.com",
      finishedTransaction: "3694.92 USD",
    },
    {
      id: "123456",
      refferedEmail: "user@example.com",
      finishedTransaction: "3694.92 USD",
    },
    {
      id: "123456",
      refferedEmail: "user@example.com",
      finishedTransaction: "3694.92 USD",
    },
    {
      id: "123456",
      refferedEmail: "user@example.com",
      finishedTransaction: "3694.92 USD",
    },
    {
      id: "123456",
      refferedEmail: "user@example.com",
      finishedTransaction: "3694.92 USD",
    },
    {
      id: "123456",
      refferedEmail: "user@example.com",
      finishedTransaction: "3694.92 USD",
    },
    {
      id: "123456",
      refferedEmail: "user@example.com",
      finishedTransaction: "3694.92 USD",
    },
    {
      id: "123456",
      refferedEmail: "user@example.com",
      finishedTransaction: "3694.92 USD",
    },
    {
      id: "123456",
      refferedEmail: "user@example.com",
      finishedTransaction: "3694.92 USD",
    },
    {
      id: "123456",
      refferedEmail: "user@example.com",
      finishedTransaction: "3694.92 USD",
    },
    {
      id: "123456",
      refferedEmail: "user@example.com",
      finishedTransaction: "3694.92 USD",
    },
    {
      id: "123456",
      refferedEmail: "user@example.com",
      finishedTransaction: "3694.92 USD",
    },
    {
      id: "123456",
      refferedEmail: "user@example.com",
      finishedTransaction: "3694.92 USD",
    },
    {
      id: "123456",
      refferedEmail: "user@example.com",
      finishedTransaction: "3694.92 USD",
    },
    {
      id: "123456",
      refferedEmail: "user@example.com",
      finishedTransaction: "3694.92 USD",
    },
  ];

  return (
    <>
      <div className={clsx(classes.wrapper)}>
        <Heading xl>My Referral</Heading>
        <div className={clsx(classes.tableWrapper, "overflow")}>
          <table className={classes.table}>
            <thead>
              <tr>
                <th>
                  <Text
                    textPlaceholer
                    sm
                    className={clsx(classes.heading, classes.firstHeading)}
                  >
                    ID
                  </Text>
                </th>
                <th>
                  <Text textPlaceholer sm className={clsx(classes.heading)}>
                    Referred Email
                  </Text>
                </th>

                <th className={classes.heading}>
                  <Text textPlaceholer sm className={clsx(classes.lastHeading)}>
                    Finished Transaction
                  </Text>
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((el, i) => (
                <tr key={i}>
                  <td>
                    <Text
                      primary
                      base
                      className={clsx(classes.item, classes.firstItem)}
                    >
                      {el.id}
                    </Text>
                  </td>
                  <td>
                    <Text primary base className={clsx(classes.item)}>
                      {el.refferedEmail}
                    </Text>
                  </td>
                  <td>
                    <Text
                      primary
                      base
                      className={clsx(classes.item, classes.lastItem)}
                    >
                      {el.finishedTransaction}
                    </Text>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default MyRefferel;
