import React, { useState } from "react";
import classes from "./ManageApiKeys.module.css";
import { Button, Text } from "components/common";
import clsx from "clsx";
import Header from "components/Auth/Header/Header";
import { copyIcon2 } from "images";
import { copyToClipboard } from "utils/utils";
import ApiKeyModal from "Modals/ApiKeyModal/ApiKeyModal";
import DeleteApiKeyModal from "Modals/DeleteApiKeyModal/DeleteApiKeyModal";
const ManageApiKeys = ({ keyType }) => {
  const [isEditingApiKey, setIsEditingApiKey] = useState(false);
  const [isAddingApiKey, setIsAddingApiKey] = useState(false);

  const [isDeletingApiKey, setIsDeletingApiKey] = useState(false);
  const data = [
    { name: "Merchant Name", key: "JkF72mTnEYdRbP4s" },
    { name: "Merchant Name", key: "G3hN8qW5sX1tZ6jY" },
    { name: "Merchant Name", key: "D9vP2kL6mR3wS7cT" },
  ];
  return (
    <>
      <section className={classes.wrapper}>
        {data.length === 0 ? (
          <Header
            className={classes.header}
            heading="No API key found"
            info="You don’t have any api key. Please click “Add API Key” button to add a api key."
          />
        ) : (
          <>
            {data.map((el, i) => (
              <div className={classes.infoContainer} key={i}>
                <Text textWhite lg className={classes.name}>
                  {el.name}
                </Text>
                <div className={classes.keyContainer}>
                  <Text lg secondary semiBold>
                    {el.key}
                  </Text>
                  <button onClick={() => copyToClipboard(el.key)}>
                    <img src={copyIcon2} alt="#" className={classes.copyIcon} />
                  </button>
                </div>
                <div className={classes.editAndDelete}>
                  <Button
                    primitive900
                    className={classes.editButton}
                    onClick={() => setIsEditingApiKey(true)}
                  >
                    Edit
                  </Button>{" "}
                  <Button
                    primitive900
                    className={classes.editButton}
                    onClick={() => setIsDeletingApiKey(true)}
                  >
                    Delete
                  </Button>
                </div>
              </div>
            ))}
          </>
        )}
      </section>

      <div className={classes.buttonContainer}>
        <Button
          className={classes.button}
          primitive100
          onClick={() => setIsAddingApiKey(true)}
        >
          Add API Key
        </Button>{" "}
        <Button className={classes.button}>See API Documentation</Button>
      </div>

      <ApiKeyModal
        isActive={isEditingApiKey || isAddingApiKey}
        onClose={() => {
          setIsAddingApiKey(false);
          setIsEditingApiKey(false);
        }}
        heading={isAddingApiKey ? "Add API Key" : "Edit API Key"}
      />
      <DeleteApiKeyModal
        isActive={isDeletingApiKey}
        onClose={() => {
          setIsDeletingApiKey(false);
        }}
      />
    </>
  );
};



export default ManageApiKeys;
