import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from 'js-cookie';

export const fetchProfiles = createAsyncThunk("fetch_profiles", async (args, {
    rejectWithValue
}) => {

    try {
        let base_url = process.env.REACT_APP_API_URL;
        let route = "/profile/profiles"
        let url = base_url + route;
        console.log(url)
        let data = await axios.get(url, {
            headers: {
                "Authorization": `Bearer ${Cookies.get("token")}`
            }
        });
        console.log(data)
        return data.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})


const fetchProfileslice = createSlice({
    name: 'fetch_profiles',
    initialState: {
        profiles: [],
        selected_profile: {},
        isLoading: false,
        isFailed: false
    },
    reducers: {
    },
    // extra reducer use builder callback
    extraReducers: (builder) => {
        builder.addCase(fetchProfiles.pending, (state, action) => {
            state.isLoading = true
            state.isFailed = false
        })
        builder.addCase(fetchProfiles.fulfilled, (state, action) => {
            state.isLoading = false
            state.isFailed = false
            state.profiles = action.payload
            state.selected_profile = localStorage.getItem("selected_profile") ? JSON.parse(localStorage.getItem("selected_profile")).profile_id : action.payload[0].profile_id
        })
        builder.addCase(fetchProfiles.rejected, (state, action) => {
            state.isLoading = false
            state.isFailed = true
        })
    }

})

export default fetchProfileslice.reducer