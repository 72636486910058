import React, { useState } from "react";
import classes from "./Sidebar.module.css";
import { FaChevronDown } from "react-icons/fa";
import { IoClose } from "react-icons/io5";

import { NavLink } from "react-router-dom";
import clsx from "clsx";
import { Heading } from "../../../components/common";
const Sidebar = ({ activeLink, setActiveLink }) => {
  const [sidebar, setSidebar] = useState(false);

  const handleClick = (navItem) => {
    setActiveLink(navItem);
    setSidebar(false);
  };
  return (
    <div className={classes.wrapper}>
      <div
        className={classes.header}
        onClick={() => setSidebar((prev) => !prev)}
      >
        <Heading xl2>{activeLink}</Heading>
        <FaChevronDown className={classes.icon} />
      </div>
      <div className={clsx(classes.navContainer, sidebar && classes.show)}>
        <div className={clsx(classes.navItems, classes.menu)}>
          <Heading sm secondary className={classes.heading}>
            Menu
          </Heading>
          <NavLink
            to="/accounts"
            className={({ isActive }) =>
              isActive
                ? clsx(classes.navItem, classes.navActive)
                : classes.navItem
            }
            onClick={() => handleClick("Accounts")}
          >
            Accounts
          </NavLink>
          <NavLink
            to="/security"
            className={({ isActive }) =>
              isActive
                ? clsx(classes.navItem, classes.navActive)
                : classes.navItem
            }
            onClick={() => handleClick("Security")}
          >
            Security
          </NavLink>
        </div>{" "}
        <div className={clsx(classes.navItems, classes.api)}>
          <Heading sm secondary className={classes.heading}>
            API
          </Heading>
          <NavLink
            to="/manage-api-key"
            className={({ isActive }) =>
              isActive
                ? clsx(classes.navItem, classes.navActive)
                : classes.navItem
            }
            onClick={() => handleClick("Manage API Key")}
          >
            Manage API Key
          </NavLink>
          <NavLink
            to="/affiliate"
            className={({ isActive }) =>
              isActive
                ? clsx(classes.navItem, classes.navActive)
                : classes.navItem
            }
            onClick={() => handleClick("Manage API Key")}
          >
            Affiliate
          </NavLink>
          <NavLink
            to="/api-documentation"
            className={({ isActive }) =>
              isActive
                ? clsx(classes.navItem, classes.navActive)
                : classes.navItem
            }
            onClick={() => handleClick("API Documentation")}
          >
            API Documentation
          </NavLink>
        </div>
        <IoClose
          className={clsx(classes.close, classes.icon)}
          onClick={() => setSidebar(false)}
        />
      </div>
    </div>
  );
};

export default Sidebar;
