
import { configureStore } from '@reduxjs/toolkit'
import fetchPaymentSlice from './payments'
import fetchRequestslice from './requests'
import fetchBalanceSlice from './balances'
import currencies from './currencies'
import networks from './networks'
import transactions from './transactions'
import profiles from './profile'
import api_keys from './api_keys'
export const store = configureStore({
    reducer: {
        payments: fetchPaymentSlice,
        requests: fetchRequestslice,
        balances: fetchBalanceSlice,
        currencies: currencies,
        networks: networks,
        transactions: transactions,
        profiles: profiles,
        api_keys: api_keys
    },
})