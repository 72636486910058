import React from "react";
import classes from "./CheckeoutTimeout.module.css";

import { Text } from "../../../components/common";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { passwordupdated as successImg } from "images";
import { Heading } from "components/common";

const CheckeoutTimeout = () => {
  const transactionDetails = [
    {
      key: "Trx. ID",
      value: "ABC12345678",
    },
    {
      key: "Amount",
      value: "1,234.00 USD",
    },
    {
      key: "Coin",
      value: "Uniswap",
    },
    {
      key: "Network",
      value: "0.0036978 ETH",
    },

    {
      key: "Status",
      value: "timeout",
    },
  ];
  return (
    <div className={classes.wrapper}>
      <div className={classes.imgAndTitle}>
        {" "}
        <img src={successImg} alt="#" className={classes.img} />
        <Heading xl3 font600 textCenter>
          Timeout
        </Heading>
      </div>

      <div className={classes.detailsContainer}>
        <Text className={classes.title}>Transaction Details</Text>

        <div className={classes.details}>
          {transactionDetails.map((el, i) => (
            <div className={classes.spaceBetween} key={i}>
              <Text secondary className={classes.key}>
                {el.key}
              </Text>
              <Text
                primary
                semiBold
                className={clsx(
                  classes.value,
                  el.value.toLowerCase() === "completed" && classes.completed
                )}
              >
                {el.value}
              </Text>
            </div>
          ))}
        </div>
      </div>

      <Link to="/" className={classes.button}>
        Done
      </Link>
    </div>
  );
};

export default CheckeoutTimeout;
