import React, { useState, useEffect, useRef } from "react";
import { IoMdClose } from "react-icons/io";
import { AiOutlineAlignRight } from "react-icons/ai";
import classes from "./Navbar.module.css";

import { Link } from "react-router-dom";

import Tabs from "../../Auth/Tabs/Tabs";
import {
  currencyIcon,
  lanugageIcon,
  mainLogo,
  notificationIcon,
  userIcon,
} from "images";
import CurrencyDropdown from "../CurrencyDropdown/CurrencyDropdown";
import LanguageDropdown from "../LanguageDropdown/LanguageDropdown";
import clsx from "clsx";
import ProfileDropdown from "../ProfileDropdown/ProfileDropdown";
import NotificationDropdown from "../NotificationDropdown/NotificationDropdown";

const Navbar = () => {
  const [sidebar, setSidebar] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  //dropdown

  const [showNotificationDropdown, setShowNotificationDropdown] =
    useState(false);

  //language dropdown
  const [showLanguageropdown, setShowLanguageropdown] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("English");

  //currencyDropdown
  const [showCurrencyropdown, setShowCurrencyropdown] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState("USD");

  //profileDropdown
  const [showProfileDropdown, setShowProfileDropdown] = useState(false);

  const userInfo = { name: "Frederick Johnson", email: "example@mail.com" };

  const handleScroll = () => {
    if (window.scrollY > 90) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div
      className={[classes.wrapper, isScrolled && classes.wrapperBg].join(" ")}
    >
      <header className={[classes.header, "container"].join(" ")}>
        <Link
          className={[classes.navItem, classes.logoContainer].join(" ")}
          to="/"
          onClick={() => setSidebar((prev) => !prev)}
        >
          {" "}
          <img
            src={process.env.REACT_APP_LOGO_WORD_URL}
            alt="#"
            className={classes.logo}
          />
        </Link>{" "}
        <div
          className={clsx(classes.tabsAndButton, sidebar && classes.sidebar)}
        >
          <Tabs
            className={classes.tabs}
            type2
            items={[
              { item: "Dashboard", to: "/dashboard" },
              { item: "Affiliate", to: "/affiliate" },
              { item: "Api Docs", to: "https://docs.triplepay.io" },
              // { item: "Docs", to: "/2" },
              // { item: "Docs", to: "/3" },
              // { item: "Docs", to: "/2" },
              // { item: "Docs", to: "/3" },
            ]}
          />
          <div className={clsx(classes.buttonContainer)}>
            <NotificationDropdown
              isActive={showNotificationDropdown}
              setIsActive={setShowNotificationDropdown}
            >
              <div
                className={clsx(
                  showNotificationDropdown && classes.activeIconContainer,
                  classes.iconContainer
                )}
                onClick={() => setShowNotificationDropdown((prev) => !prev)}
              >
                <img src={notificationIcon} alt="#" className={classes.icon} />
              </div>
            </NotificationDropdown>{" "}
            <LanguageDropdown
              isActive={showLanguageropdown}
              selectedValue={selectedLanguage}
              setIsActive={setShowLanguageropdown}
              onSelect={(val) => setSelectedLanguage(val)}
            >
              <div
                className={clsx(
                  showLanguageropdown && classes.activeIconContainer,
                  classes.iconContainer
                )}
                onClick={() => setShowLanguageropdown((prev) => !prev)}
              >
                <img src={lanugageIcon} alt="#" className={classes.icon} />
              </div>
            </LanguageDropdown>{" "}
            <CurrencyDropdown
              isActive={showCurrencyropdown}
              selectedValue={selectedCurrency}
              setIsActive={setShowCurrencyropdown}
              onSelect={(val) => setSelectedCurrency(val)}
            >
              <div
                className={clsx(
                  showCurrencyropdown && classes.activeIconContainer,
                  classes.iconContainer
                )}
                onClick={() => setShowCurrencyropdown((prev) => !prev)}
              >
                <img src={currencyIcon} alt="#" className={classes.icon} />
              </div>
            </CurrencyDropdown>{" "}
            <ProfileDropdown
              isActive={showProfileDropdown}
              setIsActive={setShowProfileDropdown}
              userInfo={userInfo}
            >
              <div
                className={clsx(
                  showProfileDropdown && classes.activeIconContainer,
                  classes.iconContainer
                )}
                onClick={() => setShowProfileDropdown((prev) => !prev)}
              >
                <img src={userIcon} alt="#" className={classes.icon} />
              </div>
            </ProfileDropdown>{" "}
            <IoMdClose
              className={classes.closeIcon}
              onClick={() => setSidebar((prev) => !prev)}
            />
          </div>{" "}
        </div>
        <AiOutlineAlignRight
          className={classes.openIcon}
          onClick={() => setSidebar((prev) => !prev)}
        />
      </header>
    </div>
  );
};

export default Navbar;
