import React, { useState } from "react";
import classes from "./Transaction.module.css";
import { downloadIcon, history as historyIcon, usdtLogo } from "images";
import { Dropdown, Heading, Input, Text } from "components/common";
import clsx from "clsx";
import { formatter } from "utils/utils";
import { LuMoveRight } from "react-icons/lu";
import Coins from "../Coins/Coins";
import { useSelector } from "react-redux";

const types = ["Received", "Transfer", "Convert", "Withdraw"];
const dates = ["Today", "Last Week", "Last 30 days", "Custom Date"];

const Transaction = () => {
  const [searchValue, setSearchValue] = useState("");
  const [showTypeDropdown, setShowTypeDropdown] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  const [showDateDropdown, setShowDateDropdown] = useState(false);
  const [selectedDate, setShowSelectedDate] = useState("");

  let data = useSelector((state) => state.transactions.transactions);
  console.log(data);
  const filterData = () => {
    return data.filter((item) => {
      const searchMatch =
        item.transaction_type.toLowerCase().includes(searchValue.toLowerCase()) ||
        item.transaction_amount.toString().includes(searchValue) ||
        // item.date.includes(searchValue) ||
        item.transaction_status.toLowerCase().includes(searchValue.toLowerCase());

      const typeMatch = !selectedType || item.type === selectedType;

      const dateMatch = (() => {
        if (!selectedDate || selectedDate === "Custom Date") return true;

        const itemDate = new Date(item.date);
        const today = new Date();

        switch (selectedDate) {
          case "Today":
            return itemDate.toDateString() === today.toDateString();
          case "Last Week":
            const lastWeek = new Date();
            lastWeek.setDate(today.getDate() - 7);
            return itemDate >= lastWeek && itemDate <= today;
          case "Last 30 days":
            const last30Days = new Date();
            last30Days.setDate(today.getDate() - 30);
            return itemDate >= last30Days && itemDate <= today;
          default:
            return true;
        }
      })();

      return searchMatch && typeMatch && dateMatch;
    });
  };

  const filteredData = filterData();
  let currencies = useSelector((state) => state.currencies.currencies);

  return (
    <section className={classes.mainWrapper}>
      <div className={clsx(classes.wrapper)}>
        <div className={classes.header}>
          <Input
            type="text"
            placeholder="Search"
            value={searchValue}
            setValue={setSearchValue}
            search
            className={classes.input}
          />
          <div className={classes.buttonContainer}>
            <Dropdown
              isActive={showTypeDropdown}
              setIsActive={setShowTypeDropdown}
              selectedType={selectedType}
              onSelect={(val) => setSelectedType(val)}
              items={types}
            >
              Type
            </Dropdown>
            <Dropdown
              isActive={showDateDropdown}
              setIsActive={setShowDateDropdown}
              selectedType={selectedDate}
              onSelect={(val) => setShowSelectedDate(val)}
              items={dates}
            >
              Date
            </Dropdown>
            <button className={classes.downloadButton}>
              <img
                src={downloadIcon}
                alt="#"
                className={classes.downloadIcon}
              />
            </button>
          </div>
        </div>

        <div className={clsx(classes.tableWrapper, "overflow")}>
          <table className={classes.table}>
            <thead>
              <tr>
                <th>
                  <Text
                    textPlaceholer
                    sm
                    className={clsx(classes.heading, classes.firstHeading)}
                  >
                    Coin
                  </Text>
                </th>
                <th>
                  <Text textPlaceholer sm className={clsx(classes.heading)}>
                    Type
                  </Text>
                </th>
                <th>
                  <Text textPlaceholer sm className={clsx(classes.heading)}>
                    Amount
                  </Text>
                </th>
                <th>
                  <Text textPlaceholer sm className={clsx(classes.heading)}>
                    Date
                  </Text>
                </th>
                <th>
                  <Text textPlaceholer sm className={clsx(classes.heading)}>
                    Status
                  </Text>
                </th>
                <th className={classes.heading}></th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((el, i) => (
                <tr key={i}>
                  <td>
                    <img
                      src={
                        // el.coin
                        currencies?.find((item) => item?.id === el?.currency)?.logo
                      }
                      alt="coin"
                      className={classes.coinIcon}
                    />

                  </td>
                  <td>
                    <Text
                      primary
                      base
                      className={clsx(classes.item, classes.type)}
                    >
                      {el.transaction_type}
                    </Text>
                  </td>
                  <td>
                    <Text primary base className={clsx(classes.item)}>
                      {formatter.format(el.transaction_amount)} USD
                    </Text>
                  </td>
                  <td>
                    <Text primary base className={clsx(classes.item)}>
                      {new Date(el.transaction_timestamp * 1000).toLocaleString()}
                    </Text>
                  </td>
                  <td className={classes.statusContainer}>
                    <Text
                      primary
                      sm
                      className={clsx(
                        classes.item,
                        classes.status,
                        el.transaction_status.toLowerCase() === "completed" &&
                        classes.completed,
                        el.transaction_status.toLowerCase() === "pending" &&
                        classes.pending,
                        el.transaction_status.toLowerCase() === "failed" && classes.failed
                      )}
                    >
                      {el.transaction_status}
                    </Text>
                  </td>
                  <td>
                    <button className={clsx(classes.link, classes.item)}>
                      <LuMoveRight className={classes.arrowRight} />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Coins />
    </section>
  );
};

export default Transaction;
