import React, { useState } from "react";
import classes from "./SelectCoin.module.css";
import {
  avaxLogo,
  bnbLogo,
  btcLogo,
  daiLogo,
  dashLogo,
  dogeLogo,
  ethLogo,
  ltcLogo,
  usdtLogo,
} from "images";

import { Heading, Input, Text } from "components/common";
import clsx from "clsx";
import { FaCircleCheck } from "react-icons/fa6";
import { formatter } from "utils/utils";

const SelectCoin = ({
  setPaymentGenerateInput,
  paymentGenerateInput,
  currencies,
  setSelectedCoin,
  className,
}) => {
  const [activeCoin, setActiveCoin] = useState("USDT");
  const [searchValue, setSearchValue] = useState("");

  const filteredCoins = currencies?.filter((coin) =>
    coin?.name.toLowerCase().includes(searchValue.toLowerCase())
  );

  return (
    <div className={clsx(classes.wrapper, className)}>
      <Input
        search
        value={searchValue}
        setValue={setSearchValue}
        placeholder="Search"
      />
      <div className={clsx(classes.coins, "overflow")}>
        {filteredCoins.map((coin, i) => (
          <div
            className={clsx(
              classes.coin,
              activeCoin === coin.name && classes.activeCoin
            )}
            onClick={() => {
              setPaymentGenerateInput({
                ...paymentGenerateInput,
                coin: coin.id,
              });
              setActiveCoin(coin.name);
              setSelectedCoin(coin);
            }}
            key={i}
          >
            <img src={coin.logo} alt="Coin" className={classes.coinImg} />
            <div className={classes.nameAndPrice}>
              <Heading semiBold lg>
                {coin.name}
              </Heading>
              <Text textTeritary sm semibold>
                {/* {formatter.format(coin.price)} */}
              </Text>
            </div>

            {activeCoin === coin.name && (
              <FaCircleCheck className={classes.checkMark} />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SelectCoin;
