import React, { useState } from "react";
import classes from "./MyMerchants.module.css";
import { addIcon, editIcon, merchantImg, searchIcon } from "images";
import { IoClose } from "react-icons/io5";
import { Button, Heading, Input } from "components/common";
import clsx from "clsx";
import { useSelector } from "react-redux";

const MyMerchants = () => {
  // const [merchants, setMerchants] = useState([ ]);

  let merchants = useSelector((state) => state.profiles.profiles);

  console.log(merchants);
  const [activeMerchant, setActiveMerchant] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [merchantName, setMerchantName] = useState("");
  const [showSearchInput, setShowSearchInput] = useState(false);
  const [showAddMerchantInput, setShowAddMerchantInput] = useState(false);
  const [showDeleteIcon, setShowDeleteIcon] = useState(false);

  const handleSearch = () => {
    setShowSearchInput(true);
    setShowAddMerchantInput(false);
    setShowDeleteIcon(false);
  };

  const handleAddingMerchant = () => {
    setShowSearchInput(false);
    setShowAddMerchantInput(true);
    setShowDeleteIcon(false);
  };

  const handleDelete = () => {
    setShowSearchInput(false);
    setShowAddMerchantInput(false);
    setShowDeleteIcon(true);
  };

  const addMerchant = () => {
    if (merchantName.trim() === "") return;
    // setMerchants([...merchants, { img: merchantImg, name: merchantName }]);
    setMerchantName("");
    setShowAddMerchantInput(false);
  };

  const deleteMerchant = (index) => {
    const updatedMerchants = merchants.filter((_, i) => i !== index);
    // setMerchants(updatedMerchants);
  };

  const filteredMerchants = merchants.filter((merchant) =>
    merchant.name.toLowerCase().includes(searchValue.toLowerCase())
  );

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <Heading semibold lg>
          Payment Profiles
        </Heading>
        <div className={classes.buttonContainer}>
          <button
            className={clsx(
              classes.button,
              showSearchInput && classes.activeButton
            )}
            onClick={handleSearch}
          >
            <img src={searchIcon} alt="Search" className={classes.icon} />
          </button>
          <button
            className={clsx(
              classes.button,
              showAddMerchantInput && classes.activeButton
            )}
            onClick={handleAddingMerchant}
          >
            <img src={addIcon} alt="Add" className={classes.icon} />
          </button>
          <button
            className={clsx(
              classes.button,
              showDeleteIcon && classes.activeButton
            )}
            onClick={handleDelete}
          >
            <img src={editIcon} alt="Edit" className={classes.icon} />
          </button>
        </div>
      </div>
      {showSearchInput && (
        <Input
          search
          placeholder="Search"
          value={searchValue}
          setValue={setSearchValue}
          className={classes.input}
        />
      )}
      {showAddMerchantInput && (
        <div className={classes.addMerchantContainer}>
          <Input
            placeholder="Enter merchant name"
            value={merchantName}
            setValue={setMerchantName}
            className={classes.input}
          />
          <Button
            wFull
            onClick={addMerchant}
            className={classes.addMerchantButton}
          >
            Add Merchant
          </Button>
        </div>
      )}
      <div className={classes.merchants}>
        {filteredMerchants.map((merchant, i) => (
          <div
            className={clsx(
              activeMerchant === i + 1 && classes.activeMerchant,
              classes.merchant
            )}
            key={i}
            onClick={() => setActiveMerchant(i + 1)}
          >
            <div className={classes.merchantImgContainer}>
              <img
                src={merchantImg}
                alt="Merchant"
                className={classes.merchantImg}
              />
            </div>
            <Heading primary={activeMerchant === i + 1} semiBold lg>
              {merchant.name}
            </Heading>
            {showDeleteIcon && (
              <button
                className={classes.delete}
                onClick={() => deleteMerchant(i)}
              >
                <IoClose className={classes.closeIcon} />
              </button>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default MyMerchants;
