import { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { FiSearch } from "react-icons/fi";

import { FaCheck, FaAngleUp, FaAngleDown } from "react-icons/fa6";

import classes from "./NetworkDropdown.module.css";
import useOnClickOutside from "hooks";

import { Text } from "components/common";
import { useSelector } from "react-redux";
const listItems = ["Arbitrum One", "Abalanche C-Chain", "BSC (BEP 20)"];
const NetworkDropdown = ({
  selectedWallet,
  isActive,
  setIsActive,
  selectedValue,
  onSelect,
  className,
}) => {
  const [filteredItems, setFilteredItems] = useState(listItems);
  const inputRef = useRef();
  const [searchValue, setSearchValue] = useState("");

  let currency = useSelector((state) => state.currencies.currencies).find((el) => el.id === selectedWallet);
  let networks = useSelector((state) => state.networks.networks);



  useEffect(() => {
    inputRef.current.focus();
  }, [isActive]);

  useEffect(() => {
    setFilteredItems(
      listItems?.filter((el) =>
        el?.toLowerCase().includes(searchValue.toLowerCase())
      )
    );
  }, [searchValue]);
  const ref = useRef();

  useOnClickOutside(ref, () => setIsActive(false));
  return (
    <div className={clsx(classes.dropdown, className)} ref={ref}>
      <div
        className={classes.labelContainer}
        onClick={() => setIsActive((prev) => !prev)}
      >
        {selectedValue ? (
          <Text>{selectedValue}</Text>
        ) : (
          <Text textTerirary base>
            Select
          </Text>
        )}
        {isActive ? (
          <FaAngleUp className={classes.arrow} />
        ) : (
          <FaAngleDown className={classes.arrow} />
        )}
      </div>
      <div className={clsx(classes.dropdownMain, isActive && classes.active)}>
        <div className={classes.searchContainer}>
          <FiSearch className={classes.searchIcon} />
          <input
            ref={inputRef}
            type="search"
            placeholder="Search"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </div>
        <div className={classes.list}>
          {filteredItems?.map((el, idx) => {
            return (
              <div
                key={"lang-list-item-" + idx}
                className={clsx(
                  classes.listItem,
                  selectedValue === el && classes.active
                )}
                onClick={() => {
                  setSearchValue("");
                  onSelect(el);
                  setIsActive(false);
                }}
              >
                <Text
                  onClick={() => {
                    onSelect(el);
                    setIsActive(false);
                  }}
                  className={classes.item}
                >
                  {el}
                </Text>

                {selectedValue === el && (
                  <FaCheck className={classes.checkmark} />
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default NetworkDropdown;
