import React, { useState } from "react";
import classes from "./AddPinModal.module.css";
import Modal from "components/common/Modal/Modal";
import { Button, Heading, Input } from "components/common";
import { IoClose } from "react-icons/io5";

import Verification from "pages/Authentication/Verification/Verification";
import Header from "components/Auth/Header/Header";
import { handleKeyDown } from "hooks";

const AddPIN = ({ isActive, onClose, pin, setPin }) => {
  const [step, setStep] = useState(1);

  return (
    <Modal
      isActive={isActive}
      onClose={() => {
        onClose();
        setStep((prev) => prev - 1);
      }}
    >
      <div className={classes.wrapper}>
        <div className={classes.header}>
          <Heading primary lg>
            Add PIN
          </Heading>
          <button
            className={classes.closeButton}
            onClick={() => {
              onClose();
              setStep((prev) => prev - 1);
            }}
          >
            <IoClose className={classes.closeIcon} />
          </button>
        </div>
        {step === 1 && (
          <Verification
            noResend
            heading="Verify Your Identity"
            info="Enter your verification code. We sent a 6 digit verification code to your email."
            setStep={setStep}
          />
        )}
        {step === 2 && (
          <>
            <div className={classes.inputContainer}>
              <Header
                heading="Set Your PIN"
                info="PIN number must be 6 digit."
              ></Header>

              <Input
                type="number"
                onKeyDown={handleKeyDown}
                value={pin}
                setValue={setPin}
                placeholder="Enter PIN"
                className={classes.input}
              />
            </div>
            <div className={classes.buttonContainer}>
              <Button
                primitive800
                onClick={() => {
                  onClose();
                  setStep((prev) => prev - 1);
                }}
              >
                Cancel
              </Button>{" "}
              <Button
                onClick={() => {
                  onClose();
                  setStep((prev) => prev - 1);
                }}
              >
                Save Pin
              </Button>
            </div>
          </>
        )}{" "}
      </div>
      ;
    </Modal>
  );
};

export default AddPIN;
