import React, { useState } from "react";
import classes from "./ApiKeyModal.module.css";
import Modal from "components/common/Modal/Modal";
import { Button, Heading, Text } from "components/common";
import { IoClose } from "react-icons/io5";
import Dropdown from "./Dropdown/Dropdown";
import Checkbox from "components/common/CheckBox/CheckBox";

const merchants = ["Merchants1", "Merchants2", "Merchants3"];
const ApiKeyModal = ({ isActive, onClose, heading }) => {
  const [isShowDropdown, setIsShowDropdown] = useState(false);
  const [selectedMerchant, setSelectedMerchant] = useState("");

  // accesss
  const [fullAccess, setFullAccess] = useState(true);

  const [processPayment, setProcessPayment] = useState(false);
  const [viewPaymentStatus, setViewPaymentStatus] = useState(false);
  const [processRefund, setProcessRefund] = useState(false);
  const [viewPaymentHistory, setViewPaymentHistory] = useState(false);
  const [createCustomer, setCreateCustomer] = useState();
  return (
    <Modal
      isActive={isActive}
      onClose={() => {
        onClose();
      }}
    >
      <div className={classes.wrapper}>
        <div className={classes.header}>
          <Heading primary lg>
            {heading}
          </Heading>
          <button
            className={classes.closeButton}
            onClick={() => {
              onClose();
            }}
          >
            <IoClose className={classes.closeIcon} />
          </button>
        </div>

        <div className={classes.container}>
          <Text lg primary textCenter className={classes.info}>
            Please choose the merchant for which you want to set the API key.
          </Text>
          <Dropdown
            isActive={isShowDropdown}
            setIsActive={setIsShowDropdown}
            selectedValue={selectedMerchant}
            onSelect={(val) => setSelectedMerchant(val)}
            dropdownItems={merchants}
          />
          <div className={classes.controlContainer}>
            <div className={classes.controlHeader}>
              <Heading base semiBold>
                Control
              </Heading>{" "}
              <div className={classes.fullAccess}>
                <Text base primary>
                  Full Access
                </Text>{" "}
                <Checkbox checked={fullAccess} setChecked={setFullAccess} />
              </div>
            </div>
            <div className={classes.others}>
              <div className={classes.access}>
                <Text base primary>
                  process_payments
                </Text>
                <Checkbox
                  checked={processPayment}
                  setChecked={setProcessPayment}
                />
              </div>{" "}
              <div className={classes.access}>
                <Text base primary>
                  view_payment_status
                </Text>
                <Checkbox
                  checked={viewPaymentStatus}
                  setChecked={setViewPaymentStatus}
                />
              </div>{" "}
              <div className={classes.access}>
                <Text base primary>
                  process_refunds
                </Text>
                <Checkbox
                  checked={processRefund}
                  setChecked={setProcessRefund}
                />
              </div>{" "}
              <div className={classes.access}>
                <Text base primary>
                  view_payment_history
                </Text>
                <Checkbox
                  checked={viewPaymentHistory}
                  setChecked={setViewPaymentHistory}
                />
              </div>{" "}
              <div className={classes.access}>
                <Text base primary>
                  create_customer
                </Text>
                <Checkbox
                  checked={createCustomer}
                  setChecked={setCreateCustomer}
                />
              </div>
            </div>
          </div>
        </div>

        <div className={classes.buttonContainer}>
          <Button
            primitive800
            className={classes.button}
            onClick={() => onClose()}
          >
            Cancel
          </Button>
          <Button className={classes.button} onClick={() => onClose()}>
            Create API Key
          </Button>
        </div>
      </div>
      ;
    </Modal>
  );
};

export default ApiKeyModal;
