import React, { useRef, useState } from "react";
import classes from "./AddPhoneNumberModal.module.css";
import Modal from "components/common/Modal/Modal";
import { Button, Heading, Input } from "components/common";
import { IoClose } from "react-icons/io5";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import Verification from "pages/Authentication/Verification/Verification";
import Header from "components/Auth/Header/Header";
import { handleKeyDown } from "hooks";
import PhoneNumberDropdown from "components/common/PhoneNumberDropdown/PhoneNumberDropdown";
import { countries } from "common/constants";

const AddPhoneNumberModal = ({ isActive, onClose, phone, setPhone }) => {
  const [step, setStep] = useState(1);

  const [isDropdownActive, setIsDropdownActive] = useState(false);
  const [selectedPhoneValue, setSelectedPhoneValue] = useState(countries[0]);
  const dropdownRef = useRef(null);

  return (
    <Modal
      isActive={isActive}
      onClose={() => {
        onClose();
        setStep((prev) => prev - 1);
      }}
    >
      <div className={classes.wrapper}>
        <div className={classes.header}>
          <Heading primary lg>
            Add Phone
          </Heading>
          <button
            className={classes.closeButton}
            onClick={() => {
              onClose();
              setStep((prev) => prev - 1);
            }}
          >
            <IoClose className={classes.closeIcon} />
          </button>
        </div>
        {step === 1 && (
          <>
            <div className={classes.inputContainer}>
              <Header heading="Add Phone" info="Enter a phone number."></Header>
              <div className={classes.phoneNumber}>
                <div ref={dropdownRef}>
                  <PhoneNumberDropdown
                    isActive={isDropdownActive}
                    selectedValue={selectedPhoneValue}
                    onSelect={(val) => {
                      setSelectedPhoneValue(val);
                      setIsDropdownActive(false);
                    }}
                  >
                    <div
                      className={classes.dropdownItem}
                      onClick={() => {
                        setIsDropdownActive((prev) => !prev);
                      }}
                    >
                      <span className={classes.code}>
                        {selectedPhoneValue.code}
                      </span>
                      {isDropdownActive ? (
                        <FaAngleUp className={classes.arrow} />
                      ) : (
                        <FaAngleDown className={classes.arrow} />
                      )}
                    </div>
                  </PhoneNumberDropdown>
                </div>
                <Input
                  className={classes.input}
                  onKeyDown={handleKeyDown}
                  type="number"
                  placeholder="Phone number"
                  value={phone}
                  setValue={setPhone}
                />
              </div>
            </div>
            <div className={classes.buttonContainer}>
              <Button
                primitive800
                onClick={() => {
                  onClose();
                  setStep((prev) => prev - 1);
                }}
              >
                Cancel
              </Button>{" "}
              <Button
                onClick={() => {
                  setStep((prev) => prev + 1);
                }}
              >
                Save Phone
              </Button>
            </div>
          </>
        )}{" "}
        {step === 2 && (
          <Verification
            noResend
            heading="Verify Your Phone"
            info="Enter your verification code. We sent a 6 digit verification code to your phone."
            setStep={setStep}
          />
        )}
      </div>
      ;
    </Modal>
  );
};

export default AddPhoneNumberModal;
