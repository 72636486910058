import React from "react";
import classes from "./AffiliatePage.module.css";
import AffiliateProgram from "components/Affiliate/AffiliateProgram/AffiliateProgram";
import MyRefferel from "components/Affiliate/MyRefferel/MyRefferel";

const AffiliatePage = () => {
  return (
    <section className={classes.wrapper}>
      <AffiliateProgram />
      <MyRefferel />
    </section>
  );
};

export default AffiliatePage;
