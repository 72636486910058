import React from "react";
import classes from "./AffiliateProgram.module.css";
import { Button, Heading, Text } from "components/common";
import clsx from "clsx";
import { copyIcon } from "images";
import { copyToClipboard } from "utils/utils";

const AffiliateProgram = () => {
  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <Heading xl2>Affiliate Program</Heading>
        <Text base primary>
          Welcome to brand_name affiliate program
        </Text>
      </div>
      <div className={classes.container}>
        <div className={clsx(classes.box, classes.estimatedProfit)}>
          <div className={classes.topHeader}>
            <Text base secondary>
              Estimated Profit
            </Text>
            <Heading xl4>12,980.00</Heading>
          </div>
          <Button wFull onClick={() => {}}>
            Request Payment
          </Button>
        </div>
        <div className={classes.box}>
          <Text base secondary>
            Total number of referral
          </Text>
          <Heading xl4>1234</Heading>
        </div>{" "}
        <div className={classes.box}>
          <Text base secondary>
            Referral this week
          </Text>
          <Heading xl4>13</Heading>
        </div>{" "}
      </div>

      <div className={classes.referContainer}>
        <Heading base semiBold className={classes.referHeading}>
          Refer Link
        </Heading>
        <div className={classes.copyAddress}>
          <Text base semiBold primary className={classes.address}>
            https://example.com/refer/123456
          </Text>
          <button
            className={classes.copyContainer}
            onClick={() =>
              copyToClipboard("https://example.com/payment-id/123456")
            }
          >
            <img src={copyIcon} alt="Copy" className={classes.copyIcon} />
          </button>
        </div>
        <Text sm secondary>
          Copy this refer link and share it with your affiliate plan.
        </Text>
      </div>
    </div>
  );
};

export default AffiliateProgram;
