import React, { forwardRef, useState } from "react";
import classes from "./DatePicker.module.css";
import DatePicker from "react-datepicker";
import { CiCalendar } from "react-icons/ci";

import "react-datepicker/dist/react-datepicker.css";
import { FaCalendarAlt } from "react-icons/fa";
import "./DatePicker.css";
import { calender } from "images";
const CustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
  <div className={classes.customInput} onClick={onClick} ref={ref}>
    <input
      className={classes.inputField}
      value={value}
      onChange={onChange}
      placeholder="Date of Birth"
    />

    <img src={calender} alt="#" className={classes.icon} />
  </div>
));
const Calender = ({ value, setValue }) => {
  return (
    <div className={classes.wrapper}>
      <DatePicker
        selected={value}
        onChange={(date) => setValue(date)}
        dateFormat="dd MMMM yyyy"
        maxDate={new Date()}
        customInput={<CustomInput />}
        showPopperArrow={false}
      />
    </div>
  );
};

export default Calender;
