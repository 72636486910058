import React, { useEffect, useState } from "react";
import classes from "./CheckoutSuccesfull.module.css";

import { Text } from "../../../components/common";
import clsx from "clsx";
import { Link, useNavigate, useParams } from "react-router-dom";
import { passwordupdated as successImg } from "images";
import { Heading } from "components/common";
import axios from "axios";


const CheckoutSuccesfull = () => {
  const { payment_id } = useParams();

  const [transactionDetails, setTransactionDetails] = useState([]);


  async function parser() {
    let from_storage = localStorage.getItem("completed_payment");
    if (from_storage) {
      let data = JSON.parse(from_storage);
      let arr = [];
      arr.push({ key: "Payment ID", value: data.payment_id });
      arr.push({ key: "Amount", value: data.amount + " " + "USD" });
      arr.push({ key: "Status", value: data.status });
      arr.push({ key: "Date", value: new Date(data.created_at_timestamp * 1000).toLocaleString() });
      setTransactionDetails(arr);
    }

    let api = process.env.REACT_APP_API_URL + "/payments/status/" + payment_id;
    let { data } = await axios.get(api);
    let arr = [];
    arr.push({ key: "Payment ID", value: data.payment_id });
    arr.push({ key: "Amount", value: data.amount + " " + "USD" });
    arr.push({ key: "Status", value: data.status });
    arr.push({ key: "Date", value: new Date(data.created_at_timestamp * 1000).toLocaleString() });
    setTransactionDetails(arr);
  }

  useEffect(() => {
    parser()
  }, []);
  return (
    <div className={classes.wrapper}>
      <div className={classes.imgAndTitle}>
        {" "}
        <img src={successImg} alt="#" className={classes.img} />
        <Heading xl3 font600 textCenter>
          Payment Successful!
        </Heading>
      </div>

      <div className={classes.detailsContainer}>
        {/* <Text className={classes.title}>Transaction Details</Text> */}

        <div className={classes.details}>
          {transactionDetails?.map((el, i) => (
            <div className={classes.spaceBetween} key={i}>
              <Text secondary className={classes.key}>
                {el?.key}
              </Text>
              <Text
                primary
                semiBold
                className={clsx(
                  classes.value,
                  el?.value?.toLowerCase() === "completed" && classes.completed
                )}
              >
                {el?.value}
              </Text>
            </div>
          ))}
        </div>
      </div>

      <Link to="/" className={classes.button}>
        Done
      </Link>
    </div>
  );
};

export default CheckoutSuccesfull;
