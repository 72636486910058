import React, { useState } from "react";
import classes from "./Coins.module.css";
import {
  addIcon,
  avaxLogo,
  bnbLogo,
  btcLogo,
  daiLogo,
  dashLogo,
  dogeLogo,
  editIcon,
  ethLogo,
  ltcLogo,
  searchIcon,
  usdtLogo,
} from "images";
import { IoClose } from "react-icons/io5";
import { Button, Heading, Input, Text } from "components/common";
import clsx from "clsx";
import { useSelector } from "react-redux";

const Coins = () => {
  const [activeCoin, setActiveCoin] = useState(1);
  // const [coins, setCoins] = useState([
  //   // { img: usdtLogo, name: "USDT", price: "0.00", usdPrice: "$0.00" },
  //   // { img: avaxLogo, name: "AVAX", price: "0.00", usdPrice: "$0.00" },
  //   // { img: bnbLogo, name: "BNB", price: "0.00", usdPrice: "$0.00" },
  //   // { img: btcLogo, name: "BTC", price: "0.00", usdPrice: "$0.00" },
  //   // { img: daiLogo, name: "DAI", price: "0.00", usdPrice: "$0.00" },
  //   // { img: dashLogo, name: "DASH", price: "0.00", usdPrice: "$0.00" },
  //   // { img: ethLogo, name: "ETH", price: "0.00", usdPrice: "$0.00" },
  //   // { img: ltcLogo, name: "LTC", price: "0.00", usdPrice: "$0.00" },
  //   // { img: dogeLogo, name: "DOGE", price: "0.00", usdPrice: "$0.00" },
  // ]);

  const coins = useSelector((state) => state.balances.balances);
  const currencies = useSelector((state) => state.currencies.currencies);

  const [searchValue, setSearchValue] = useState("");
  const [blockChainName, setBlockChainName] = useState("");
  const [hash, setHash] = useState("");
  const [coinOrTokenName, setCoinOrTokenName] = useState("");
  const [showSearchInput, setShowSearchInput] = useState(false);
  const [showAddCoinInput, setShowAddCoinInput] = useState(false);
  const [showDeleteIcon, setShowDeleteIcon] = useState(false);

  const handleSearch = () => {
    setShowSearchInput(true);
    setShowAddCoinInput(false);
    setShowDeleteIcon(false);
  };

  const handleAddingCoin = () => {
    setShowAddCoinInput(true);
    setShowSearchInput(false);
    setShowDeleteIcon(false);
  };

  const handleDelete = () => {
    setShowAddCoinInput(false);
    setShowSearchInput(false);
    setShowDeleteIcon(true);
  };

  const deleteCoin = (index) => {
    const updateCoins = coins.filter((_, i) => i !== index);
    // setCoins(updateCoins);
  };

  const addCoin = () => {
    if (blockChainName && hash && coinOrTokenName) {
      const newCoin = {
        img: usdtLogo, // You can add logic to set an image for the new coin if needed
        name: coinOrTokenName,
        price: "0.00",
        usdPrice: "$0.00",
      };
      // setCoins([...coins, newCoin]);

      setShowAddCoinInput(false);
    } else {
      alert("Please fill in all fields to add a coin.");
    }
  };

  // const filteredCoins = coins.filter((coin) =>
  //   coin.currency?.toLowerCase().includes(searchValue.toLowerCase())
  // );

  const filteredCoins = coins
    .filter((coin) =>
      coin.currency?.toLowerCase().includes(searchValue.toLowerCase())
    )?.sort((a, b) => {
      const currencyA = currencies?.find((c) => c.id == a.currency);
      const currencyB = currencies?.find((c) => c.id == b.currency);
      return currencyA?.ui_priority - currencyB?.ui_priority;
    });

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <Heading semibold lg>
          Wallet
        </Heading>
        <div className={classes.buttonContainer}>
          <button
            className={clsx(
              classes.button,
              showSearchInput && classes.activeButton
            )}
            onClick={handleSearch}
          >
            <img src={searchIcon} alt="Search" className={classes.icon} />
          </button>
          <button
            className={clsx(
              classes.button,
              showAddCoinInput && classes.activeButton
            )}
            onClick={handleAddingCoin}
          >
            <img src={addIcon} alt="Add" className={classes.icon} />
          </button>
          <button
            className={clsx(
              classes.button,
              showDeleteIcon && classes.activeButton
            )}
            onClick={handleDelete}
          >
            <img src={editIcon} alt="Edit" className={classes.icon} />
          </button>
        </div>
      </div>
      {showSearchInput && (
        <Input
          search
          placeholder="Search"
          value={searchValue}
          setValue={setSearchValue}
          className={classes.input}
        />
      )}
      {showAddCoinInput && (
        <div className={classes.addCoinContainer}>
          <Input
            placeholder="Enter blockchain name"
            value={blockChainName}
            setValue={setBlockChainName}
            className={classes.input}
          />
          <Input
            placeholder="Enter hash"
            value={hash}
            setValue={setHash}
            className={classes.input}
          />
          <Input
            placeholder="Enter coin or token name"
            value={coinOrTokenName}
            setValue={setCoinOrTokenName}
            className={classes.input}
          />
          <Button wFull className={classes.addCoinButton} onClick={addCoin}>
            Add Coin
          </Button>
        </div>
      )}
      <div className={clsx(classes.coins, "overflow")}>
        {filteredCoins.map((coin, i) => (
          <div
            className={clsx(
              classes.coin,
              activeCoin === i + 1 && classes.activeCoin
            )}
            onClick={() => setActiveCoin(i + 1)}
            key={i}
          >
            <img src={currencies.find((c) => c.id === coin.currency)?.logo} alt="Coin" className={classes.coinImg} />
            <Heading semiBold lg>
              {currencies.find((c) => c.id === coin.currency)?.name}
            </Heading>
            <div className={classes.priceContainer}>
              <Text secondary base semibold>
                {coin.balance} {coin?.currency?.toUpperCase()}
              </Text>
              <Text textTeritary sm semibold>
                ≈{coin.usdPrice}
              </Text>
            </div>
            {showDeleteIcon && (
              <button className={classes.delete} onClick={() => deleteCoin(i)}>
                <IoClose className={classes.closeIcon} />
              </button>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Coins;
