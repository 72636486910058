import React, { useState } from "react";
import classes from "./ChangePasswordModal.module.css";
import Modal from "components/common/Modal/Modal";
import { Button, Heading, Input } from "components/common";
import { IoClose } from "react-icons/io5";

import Verification from "pages/Authentication/Verification/Verification";
import Header from "components/Auth/Header/Header";

const ChangePasswordModal = ({
  isActive,
  onClose,
  password,
  setPassword,
  confirmPassword,
  setConfirmPassword,
}) => {
  const [step, setStep] = useState(1);

  return (
    <Modal
      isActive={isActive}
      onClose={() => {
        onClose();
        setStep((prev) => prev - 1);
      }}
    >
      <div className={classes.wrapper}>
        <div className={classes.header}>
          <Heading primary lg>
            Change Password
          </Heading>
          <button
            className={classes.closeButton}
            onClick={() => {
              onClose();
              setStep((prev) => prev - 1);
            }}
          >
            <IoClose className={classes.closeIcon} />
          </button>
        </div>
        {step === 1 && (
          <Verification
            noResend
            heading="Verify Your Identity"
            info="Enter your verification code. We sent a 6 digit verification code to your email."
            setStep={setStep}
          />
        )}
        {step === 2 && (
          <>
            <div className={classes.inputContainer}>
              <Header
                heading="Change Password"
                info="Make sure your password is enough strong to make your account more secure."
              ></Header>
              <div className={classes.inputs}>
                <Input
                  type="password"
                  value={password}
                  setValue={setPassword}
                  placeholder="New Password"
                  className={classes.input}
                />{" "}
                <Input
                  type="password"
                  value={confirmPassword}
                  setValue={setConfirmPassword}
                  placeholder="Confirm New Password"
                  className={classes.input}
                />
              </div>
            </div>
            <div className={classes.buttonContainer}>
              <Button
                primitive800
                onClick={() => {
                  onClose();
                  setStep((prev) => prev - 1);
                }}
              >
                Cancel
              </Button>{" "}
              <Button
                onClick={() => {
                  onClose();
                  setStep((prev) => prev - 1);
                }}
              >
                Save Password
              </Button>
            </div>
          </>
        )}{" "}
      </div>
      ;
    </Modal>
  );
};

export default ChangePasswordModal;
