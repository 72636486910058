import { Route, Routes } from "react-router-dom";
import AuthenticationLayout from "./Layout/AuthenticationLayout/AuthenticationLayout";
import Signup from "./pages/Authentication/Signup/Signup";
import Login from "./pages/Authentication/Login/Login";
import ForgotPassword from "./pages/Authentication/ForgotPassword/ForgotPassword";
import PasswordUpdated from "./pages/Authentication/ForgotPassword/PasswordUpdated/PasswordUpdated";
import HomeLayout from "./Layout/HomeLayout/HomeLayout";
import Home from "./pages/Home/Home";
import AccountSettingsLayout from "Layout/AccountSettingsLayout/AccountSettingsLayout";
import Accounts from "pages/AccountSettings/Accounts/Accounts";
import SecurityPage from "pages/AccountSettings/SecurityPage/SecurityPage";
import ManageApiKeys from "pages/AccountSettings/ManageApiKeys/ManageApiKeys";
import Security from "components/AccountSettings/SecurityComponents/Security/Security";
import WalletLayout from "Layout/WalletLayout/WalletLayout";
import BusinessWallet from "pages/Wallet/BusinessWallet/BusinessWallet";
import CheckoutLayout from "Layout/CheckoutLayout/CheckoutLayout";
import Checkout from "pages/Checkout/Checkout";
import CheckoutSuccesfull from "pages/Checkout/CheckoutSuccesfull/CheckoutSuccesfull";
import CheckeoutTimeout from "pages/Checkout/ChckeoutTimeout/CheckeoutTimeout";
import CheckoutUnderPaid from "pages/Checkout/CheckoutUnderPaid/CheckoutUnderPaid";

// toastify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ContactPage from "pages/ContactPage/ContactPage";
import AffiliatePage from "pages/AffiliatePage/AffiliatePage";
import axios from "axios";
import { useDispatch } from "react-redux";
import { fetchPayments } from "./redux/payments";
import { fetchRequests } from "./redux/requests";
import { fetchBalances } from "./redux/balances";
import { fetchCurrencies } from "./redux/currencies";
import { fetchTransactions } from "./redux/transactions";
import { fetchProfiles } from "./redux/profile";
import { fetchApiKeys } from "./redux/api_keys";
import { useEffect } from "react";
// toastify
function App() {
  const dispatch = useDispatch();
  function dispatchAll() {
    dispatch(fetchRequests());
    dispatch(fetchPayments());
    dispatch(fetchBalances());
    dispatch(fetchCurrencies());
    dispatch(fetchTransactions());
    dispatch(fetchProfiles());
    dispatch(fetchApiKeys());
  }

  useEffect(() => {
    dispatchAll();
  }, []);


  async function generate_code(email, action, mechanism) {
    try {
      let api = process.env.REACT_APP_API_URL + "/auth/generate_code";
      let body = {
        email: email,
        action: action,
        mechanism: mechanism
      }

      let { data, status } = await axios.post(api, body);

      if (status == 200) {
        return true;
      } else {
        return false
      }

    } catch (e) {
    }
  }

  async function validate_code(code, action) {
    try {
      let api = process.env.REACT_APP_API_URL + "/auth/validate_code";
      let body = {
        code: code,
        action: action
      }

      let { data, status } = await axios.post(api, body);
      if (data.is_valid == true) {
        return true;
      } else {
        return false
      }

    } catch (e) {
    }
  }



  return (
    <>
      <ToastContainer />
      <Routes>
        {/* Home and contact */}
        <Route path="" element={<HomeLayout />}>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<ContactPage />} />
        </Route>
        {/* Authentication */}
        <Route path="/" element={<AuthenticationLayout />}>
          <Route path="login" element={<Login generate_code={generate_code} validate_code={validate_code} />} />
          <Route path="sign-up" element={<Signup validate_code={validate_code} generate_code={generate_code} />} />

          <Route path="forgot-password" element={<ForgotPassword />} />
        </Route>
        {/* Wallet */}
        <Route path="" element={<WalletLayout />}>
          <Route path="/dashboard" element={<BusinessWallet />} />
          <Route path="/affiliate" element={<AffiliatePage />} />
        </Route>
        <Route path="" element={<AccountSettingsLayout />}>
          <Route path="/accounts" element={<Accounts />} />
          <Route path="/security" element={<Security />} />
          <Route path="/manage-api-key" element={<ManageApiKeys />} />
        </Route>
        <Route path="/" element={<CheckoutLayout />}>
          <Route path="checkout/:payment_id" element={<Checkout />} />

          <Route
            path="checkout-success/:payment_id"
            element={<CheckoutSuccesfull />}
          />
          <Route path="checkout-timeout" element={<CheckeoutTimeout />} />
          <Route path="checkout-underpaid" element={<CheckoutUnderPaid />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
