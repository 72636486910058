import React, { useState } from "react";
import classes from "./Enable2FaModal.module.css";
import Modal from "components/common/Modal/Modal";
import { Button, Heading, Text } from "components/common";
import { IoClose } from "react-icons/io5";

import { copyIcon, qrCode } from "images";
import { copyToClipboard } from "utils/utils";
import Verification from "pages/Authentication/Verification/Verification";

const Enable2FaModal = ({ isActive, onClose }) => {
  const [step, setStep] = useState(1);

  return (
    <Modal isActive={isActive} onClose={onClose}>
      <div className={classes.wrapper}>
        <div className={classes.header}>
          <Heading primary lg>
            Enable 2FA
          </Heading>
          <button className={classes.closeButton} onClick={onClose}>
            <IoClose className={classes.closeIcon} />
          </button>
        </div>
        {step === 1 && (
          <>
            <div className={classes.addressContainer}>
              <div className={classes.addressAndInfo}>
                <Text primary lg>
                  Facing problem to scanning the QR code. Manually enter this
                  code into your Google Authenticator app.
                </Text>
                <div className={classes.copyAddress}>
                  <Text base semiBold primary className={classes.address}>
                    Y7XOOJRQNJGEI
                  </Text>
                  <button
                    className={classes.copyContainer}
                    onClick={() => copyToClipboard("Y7XOOJRQNJGEI")}
                  >
                    <img
                      src={copyIcon}
                      alt="Copy"
                      className={classes.copyIcon}
                    />
                  </button>
                </div>
              </div>{" "}
              <div className={classes.qrCodeContainer}>
                {" "}
                <img src={qrCode} alt="QrCode" className={classes.qrCode} />
                <Text textWhite base semiBold textCenter>
                  Scan this qr-code with google authenticator.
                </Text>
              </div>
            </div>
            <div className={classes.buttonContainer}>
              <Button primitive800 onClick={onClose}>
                Cancel
              </Button>{" "}
              <Button
                onClick={() => {
                  setStep((prev) => prev + 1);
                }}
              >
                Continue
              </Button>
            </div>
          </>
        )}

        {step === 2 && (
          <Verification
            noResend
            heading="Verification"
            info="Enter your 6 digit google authenticator code to verify your account."
            onCancel={onClose}
            setStep={setStep}
          />
        )}
      </div>
      ;
    </Modal>
  );
};

export default Enable2FaModal;
