import React, { useState } from "react";
import classes from "./PaymentHistory.module.css";
import { downloadIcon } from "images";
import { Dropdown, Input, Text } from "components/common";
import clsx from "clsx";
import { useSelector } from "react-redux";

const allStatus = ["Waiting", "Completed"];
const dates = ["Today", "Last Week", "Last 30 days", "Custom Date"];

const PaymentHistory = () => {
  const [searchValue, setSearchValue] = useState("");
  // type dropdown
  const [showStatusDropdown, setShowStatusDropdown] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  // date dropdown
  const [showDateDropdown, setShowDateDropdown] = useState(false);
  const [selectedDate, setShowSelectedDate] = useState("");

  let data = useSelector((state) => state.payments.payments);
  const parseDate = (dateString) => {
    const [datePart, timePart] = dateString.split(", ");
    const [day, month, year] = datePart.split("-");
    return new Date(`${year}-${month}-${day}T${timePart}`);
  };

  const filterData = () => {
    return data.filter((item) => {
      const searchMatch =
        item.payment_id.toLowerCase().includes(searchValue.toLowerCase()) ||
        item.external_id.toLowerCase().includes(searchValue.toLowerCase()) ||
        item.status.toLowerCase().includes(searchValue.toLowerCase());

      const statusMatch = !selectedStatus || item.status === selectedStatus;

      const dateMatch = (() => {
        if (!selectedDate || selectedDate === "Custom Date") return true;

        const itemDate = parseDate(item.created_at);
        const today = new Date();

        switch (selectedDate) {
          case "Today":
            return itemDate.toDateString() === today.toDateString();
          case "Last Week":
            const lastWeek = new Date();
            lastWeek.setDate(today.getDate() - 7);
            return itemDate >= lastWeek && itemDate <= today;
          case "Last 30 days":
            const last30Days = new Date();
            last30Days.setDate(today.getDate() - 30);
            return itemDate >= last30Days && itemDate <= today;
          default:
            return true;
        }
      })();

      return searchMatch && statusMatch && dateMatch;
    });
  };

  const filteredData = filterData();

  return (
    <div className={clsx(classes.wrapper)}>
      <div className={classes.header}>
        <Input
          type="text"
          placeholder="Search"
          value={searchValue}
          setValue={setSearchValue}
          search
          className={classes.input}
        />
        <div className={classes.buttonContainer}>
          <Dropdown
            isActive={showStatusDropdown}
            setIsActive={setShowStatusDropdown}
            selectedType={selectedStatus}
            onSelect={(val) => {
              setSelectedStatus(val);
            }}
            items={allStatus}
          >
            Status
          </Dropdown>
          <Dropdown
            isActive={showDateDropdown}
            setIsActive={setShowDateDropdown}
            selectedType={selectedDate}
            onSelect={(val) => {
              setShowSelectedDate(val);
            }}
            items={dates}
          >
            Date
          </Dropdown>
          <button className={classes.downloadButton}>
            <img src={downloadIcon} alt="#" className={classes.downloadIcon} />
          </button>
        </div>
      </div>

      <div className={clsx(classes.tableWrapper, "overflow")}>
        <table className={classes.table}>
          <thead>
            <tr>
              <th>
                <Text
                  textPlaceholer
                  sm
                  className={clsx(classes.heading, classes.firstHeading)}
                >
                  Payment ID
                </Text>
              </th>
              <th>
                <Text textPlaceholer sm className={clsx(classes.heading)}>
                  External ID
                </Text>
              </th>
              <th>
                <Text textPlaceholer sm className={clsx(classes.heading)}>
                  Amount
                </Text>
              </th>
              <th>
                <Text textPlaceholer sm className={clsx(classes.heading)}>
                  Amount received/requested
                </Text>
              </th>
              <th>
                <Text textPlaceholer sm className={clsx(classes.heading)}>
                  Status
                </Text>
              </th>
              <th>
                <Text textPlaceholer sm className={clsx(classes.heading)}>
                  Created / Last updated
                </Text>
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((el, i) => (
              <tr key={i}>
                <td>
                  <Text
                    primary
                    base
                    className={clsx(classes.item, classes.firstItem)}
                  >
                    {el.payment_id}
                  </Text>
                </td>
                <td>
                  <Text primary base className={clsx(classes.item)}>
                    {el.external_id ? el.external_id : "-"}
                  </Text>
                </td>
                <td>
                  <Text primary base className={clsx(classes.item)}>
                    {el.amount} USD
                  </Text>
                </td>
                <td>
                  <div className={classes.amountContainer}>
                    <Text primary base className={clsx(classes.item)}>
                      {Number(el.amount_received).toFixed(2)} USD /
                    </Text>{" "}
                    <Text primary base className={clsx(classes.item)}>
                      {Number(el.amount).toFixed(2)} USD
                    </Text>
                  </div>
                </td>
                <td className={classes.statusContainer}>
                  <Text
                    primary
                    sm
                    className={clsx(
                      classes.item,
                      classes.status,
                      el.status.toLowerCase() === "completed" &&
                      classes.finished,
                      el.status.toLowerCase() === "waiting" &&
                      classes.waiting,
                      el.status.toLowerCase() === "partiallypaid" &&
                      classes.waiting,
                      el.status.toLowerCase() === "waiting" && classes.expired
                    )}
                  >
                    {el.status}
                  </Text>
                </td>
                <td className={classes.dateContainer}>
                  <Text primary base className={clsx(classes.item)}>
                    {new Date(el.created_at_timestamp * 1000).toLocaleString()}
                  </Text>
                  <Text primary base className={clsx(classes.item)}>
                    {new Date(el.created_at_timestamp * 1000).toLocaleString()}
                  </Text>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PaymentHistory;
