import React, { useState } from "react";
import classes from "./SelectNetwork.module.css";
import {
  arbitrum,
  avalanche,
  base,
  blast,
  bnbChain,
  celo,
  ethereum,
  optimism,
  polygon,
} from "images";
import { GoInfo } from "react-icons/go";
import { Heading, Input, Text } from "components/common";
import clsx from "clsx";
import { FaCircleCheck } from "react-icons/fa6";

const SelectNetwork = ({
  paymentGenerateInput,
  setPaymentGenerateInput,
  selectedCoin,
  networks,
  className,
}) => {
  const [activeNetwork, setActiveNetwork] = useState("Arbitrum");
  const [searchValue, setSearchValue] = useState("");

  // const [networks, setNetworks] = useState([
  //   { img: bnbChain, name: "Bnb Chain", id: "bsc" },
  // ]);

  // const filteredNetworks = networks.filter((network) =>
  //   network.name.toLowerCase().includes(searchValue.toLowerCase())
  // );

  return (
    <div className={clsx(classes.wrapper, className)}>
      {" "}
      <div className={classes.infoContainer}>
        <GoInfo className={classes.infoIcon} />
        <Text sm primaryDefault semiBold>
          Choose 'Pay in wallet' to click the button, scan the QR code, or copy
          and paste the payment address into your wallet.
        </Text>
      </div>
      <div className={classes.networksWrapper}>
        <Input
          search
          value={searchValue}
          setValue={setSearchValue}
          placeholder="Search"
        />
        <div className={clsx(classes.networks, "overflow")}>
          {selectedCoin.network_config.map((network, i) => (
            <div
              className={clsx(
                classes.network,
                activeNetwork ===
                  networks.find((a) => a.id == network.network_id).name &&
                  classes.activeNetwork
              )}
              onClick={() => {
                setPaymentGenerateInput({
                  ...paymentGenerateInput,
                  network: network.network_id,
                });
                setActiveNetwork(
                  networks.find((a) => a.id == network.network_id).name
                );
              }}
              key={i}
            >
              <img
                src={networks.find((a) => a.id == network.network_id).logo}
                alt="Network Img"
                className={classes.networkImg}
              />

              <Heading semiBold lg>
                {/* {network.network_id} */}
                {networks.find((a) => a.id == network.network_id).name}
              </Heading>

              {activeNetwork ===
                networks.find((a) => a.id == network.network_id).name && (
                <FaCircleCheck className={classes.checkMark} />
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SelectNetwork;
