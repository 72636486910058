import React, { useState } from "react";
import classes from "./PaymentMethod.module.css";
import {
  binance,
  brandlogo,
  coinGate,
  coinsPaid,
  featureLogo,
  star,
} from "images";
import { Heading, Text } from "components/common";
import clsx from "clsx";

const PaymentMethod = ({ setPaymentMethod, className }) => {
  const [active, setActive] = useState(1);
  const methods = [
    {
      logo: process.env.REACT_APP_LOGO_URL,
      logoWidth: 12,
      name: "Crypto Payment",

      id: "crypto",
      features: ["100% secure"],
    },
    {
      logo: process.env.REACT_APP_LOGO_URL,
      logoWidth: 12,
      name: "Triplepay App (soon)",
      id: "triplepay",
      features: ["Instant payment", "No need confirmation", "100% secure"],
    },
    // {
    //   logo: coinGate,
    //   name: "Pay with Binance",
    //   features: ["Instant payment", "No need confirmation", "100% secure"],
    // },
    // {
    //   logo: "https://i.ibb.co/rt08R4P/uns-round-removebg-preview.png",
    //   logoWidth: 35,
    //   name: "Pay with UNS",
    //   id: "uns",
    //   features: ["Instant payment", "5% Cashback", "100% secure"],
    // },
  ];
  return (
    <div className={clsx(classes.methods, className)}>
      {methods?.map((method, i) => (
        <div
          className={clsx(classes.method, active === i + 1 && classes.active)}
          key={i}
          onClick={() => {
            setPaymentMethod(method.id);
            setActive(i + 1);
          }}
        >
          <div className={classes.logoContainer}>
            <img src={method.logo} alt="#" width={method.logoWidth} />
          </div>
          <div className={classes.infoContainer}>
            <Heading lg className={classes.name}>
              {method.name}
            </Heading>
            {active === i + 1 &&
              method?.features?.map((feature, id) => (
                <div className={classes.feature} key={id}>
                  <img
                    src={featureLogo}
                    alt="#"
                    className={classes.featureLogo}
                  />
                  <Text xs secondary>
                    {feature}
                  </Text>
                </div>
              ))}
          </div>

          {active === i + 1 && (
            <img src={star} alt="#" className={classes.star} />
          )}
        </div>
      ))}
    </div>
  );
};

export default PaymentMethod;
