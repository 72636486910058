import React, { useState, useEffect, useRef } from "react";
import { IoMdClose } from "react-icons/io";
import { AiOutlineAlignRight } from "react-icons/ai";
import classes from "./Navbar.module.css";

import { Link } from "react-router-dom";

import {
  currencyIcon,
  lanugageIcon,
  mainLogo,
  notificationIcon,
  userIcon,
} from "images";
import LanguageDropdown from "components/Wallet/LanguageDropdown/LanguageDropdown";
import clsx from "clsx";
import ProfileDropdown from "components/Wallet/ProfileDropdown/ProfileDropdown";
import NotificationDropdown from "components/Wallet/NotificationDropdown/NotificationDropdown";
import CurrencyDropdown from "components/Wallet/CurrencyDropdown/CurrencyDropdown";
import { Button, Heading } from "components/common";

const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 90) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div
      className={[classes.wrapper, isScrolled && classes.wrapperBg].join(" ")}
    >
      <header className={[classes.header, "container"].join(" ")}>
        <Link
          className={[classes.navItem, classes.logoContainer].join(" ")}
          to="/"
        >
          {" "}
          <img src={process.env.REACT_APP_LOGO_WORD_URL} alt="#" className={classes.logo} />
        </Link>{" "}
        <Heading xl2 className={classes.heading}>
          Checkout
        </Heading>
        <Button to="/" transparent className={clsx(classes.walletButton)}>
          Back to Home
        </Button>
      </header>
    </div>
  );
};

export default Navbar;
