import React, { useState } from "react";
import classes from "./TransferModal.module.css";
import Modal from "components/common/Modal/Modal";
import { Button, Heading, Input, Text } from "components/common";
import { IoClose } from "react-icons/io5";
import WalletDropdown from "components/Wallet/WalletDropdown/WalletDropdown";
import NetworkDropdown from "components/Wallet/NetworkDropdown/NetworkDropdown";

import clsx from "clsx";
import { handleKeyDown } from "hooks";
import TransactionDetailsModal from "Modals/TransactionDetailsModal/TransactionDetailsModal";

const transactionDetails = [
  {
    key: "Transaction Type",
    value: "Transfer",
  },
  {
    key: "Transaction ID",
    value: "ABC12345678",
  },
  {
    key: "Address",
    value: "VpQP4yWjq2SKccK5d8o2BxW7",
  },
  {
    key: "Transfer From",
    value: "USDT Account",
  },
  {
    key: "Amount",
    value: "1.00 USDT",
  },
  {
    key: "Received Date",
    value: "13-5-2024",
  },
  {
    key: "Status",
    value: "Completed",
  },
];

const TransferModal = ({ isActive, onClose }) => {
  const [showWalletDropdown, setShowWalletDropdown] = useState(false);
  const [selectedWallet, setSelectedWallet] = useState("");

  const [showNetworkDropdown, setShowNetworkDropdown] = useState(false);
  const [selectedNetwork, setSelectedNetwork] = useState("");

  const [amount, setAmount] = useState("");

  const [showTransactionDetailsModal, setShowTransactionDetailsModal] =
    useState(false);
  return (
    <>
      <Modal isActive={isActive} onClose={onClose}>
        <div className={classes.wrapper}>
          <div className={classes.header}>
            <Heading primary lg>
              Transfer
            </Heading>
            <button className={classes.closeButton} onClick={onClose}>
              <IoClose className={classes.closeIcon} />
            </button>
          </div>
          <div className={classes.selectContainer}>
            <Heading xl semiBold className={classes.label}>
              Select Wallet
            </Heading>
            <WalletDropdown
              isActive={showWalletDropdown}
              setIsActive={setShowWalletDropdown}
              selectedValue={selectedWallet}
              onSelect={(val) => setSelectedWallet(val)}
            />{" "}
            <Heading xl semiBold className={classes.label}>
              Select Network 
            </Heading>
            <div className={classes.networkContainer}>
              <NetworkDropdown
                className={!selectedWallet && classes.disabled}
                isActive={selectedWallet && showNetworkDropdown}
                setIsActive={setShowNetworkDropdown}
                selectedValue={selectedNetwork}
                onSelect={(val) => setSelectedNetwork(val)}
              />
              {selectedWallet && selectedNetwork && (
                <div className={clsx(classes.listItem)}>
                  <img
                    src={selectedWallet.img}
                    alt="#"
                    className={classes.img}
                  />
                  <p className={classes.left}>{selectedWallet.name}</p>

                  <Text base textTeritary className={classes.price}>
                    {selectedWallet.price}
                  </Text>
                </div>
              )}
              {!selectedWallet && (
                <Text primary sm className={classes.info}>
                  You need to select wallet first.
                </Text>
              )}
            </div>{" "}
            {/* enter Amount */}
            <Heading xl semiBold className={classes.label}>
              Enter Amount
            </Heading>
            <div className={classes.amountInputContainer}>
              <div>
                <Input
                  value={amount}
                  setValue={setAmount}
                  type="number"
                  onKeyDown={handleKeyDown}
                  className={clsx(classes.input, classes.amountInput)}
                  placeholder="0.00"
                />
              </div>
              {amount && (
                <Text primary sm className={classes.info}>
                  Limit
                  <span className={classes.fee}>1 - 1000000.00 USDT</span>
                </Text>
              )}
              {!amount && (
                <Text primary sm className={classes.info}>
                  Enter amount to see transfer limit
                </Text>
              )}
            </div>{" "}
          </div>

          <div className={classes.buttonContainer}>
            <Button primitive800 onClick={onClose}>
              Cancel
            </Button>{" "}
            <Button
              onClick={() => {
                onClose();
                setShowTransactionDetailsModal(true);
              }}
            >
              Transfer
            </Button>
          </div>
        </div>
        ;
      </Modal>{" "}
      <TransactionDetailsModal
        details={transactionDetails}
        isActive={showTransactionDetailsModal}
        onClose={() => setShowTransactionDetailsModal(false)}
      />
    </>
  );
};

export default TransferModal;
