import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from 'js-cookie';

export const fetchCurrencies = createAsyncThunk("fetch_currencies", async (args, {
    rejectWithValue
}) => {

    try {
        let base_url = process.env.REACT_APP_API_URL;
        let route = "/currencies"
        let url = base_url + route;

        let data = await axios.get(url, {
            headers: {
                "Authorization": `Bearer ${Cookies.get("token")}`
            }
        });

        let sorted = data.data.sort((a, b) => a.ui_priority - b.ui_priority);
        return sorted
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})


const fetchCurrencieslice = createSlice({
    name: 'fetch_currencies',
    initialState: {
        currencies: [],
        isLoading: false,
        isFailed: false
    },
    reducers: {
    },
    // extra reducer use builder callback
    extraReducers: (builder) => {
        builder.addCase(fetchCurrencies.pending, (state, action) => {
            state.isLoading = true
            state.isFailed = false
        })
        builder.addCase(fetchCurrencies.fulfilled, (state, action) => {
            state.isLoading = false
            state.isFailed = false
            state.currencies = action.payload
        })
        builder.addCase(fetchCurrencies.rejected, (state, action) => {
            state.isLoading = false
            state.isFailed = true
        })
    }

})

export default fetchCurrencieslice.reducer