import { useEffect, useRef, useState } from "react";
import clsx from "clsx";

import { FaChevronDown, FaChevronUp } from "react-icons/fa6";
import classes from "./Dropdown.module.css";

import useOnClickOutside from "hooks";
import { Text } from "components/common";
import { FiSearch } from "react-icons/fi";

const Dropdown = ({
  isActive,
  dropdownItems,
  selectedValue,
  onSelect,
  setIsActive,
}) => {
  const [filteredItems, setFilteredItems] = useState(dropdownItems);
  const inputRef = useRef();

  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    inputRef.current.focus();
  }, [isActive]);

  useEffect(() => {
    setFilteredItems(
      dropdownItems?.filter((el) =>
        el.toLowerCase().includes(searchValue.toLowerCase())
      )
    );
  }, [searchValue]);

  const ref = useRef();

  useOnClickOutside(ref, () => setIsActive(false));
  return (
    <div className={clsx(classes.dropdown, classes.noTheme)} ref={ref}>
      <div
        className={classes.labelContainer}
        onClick={() => setIsActive((prev) => !prev)}
      >
        {selectedValue ? (
          <Text lg textTeritary>
            {selectedValue}
          </Text>
        ) : (
          <Text lg secondary>
            Select Merchant
          </Text>
        )}
        {isActive ? (
          <FaChevronUp className={classes.arrow} />
        ) : (
          <FaChevronDown className={classes.arrow} />
        )}
      </div>
      <div className={clsx(classes.dropdownMain, isActive && classes.active)}>
        <div className={classes.searchContainer}>
          <FiSearch className={classes.searchIcon} />
          <input
            ref={inputRef}
            type="search"
            placeholder="Search"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </div>
        <div className={clsx(classes.list, "overflow")}>
          {filteredItems?.map((item, idx) => {
            return (
              <Text
                key={"id-" + idx}
                className={clsx(
                  classes.listItem,
                  selectedValue === item && classes.active
                )}
                onClick={() => {
                  setIsActive(false);
                  onSelect(item);
                }}
              >
                {item}
              </Text>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Dropdown;
