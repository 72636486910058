import React, { useState, useEffect } from "react";
import classes from "./ImageSlider.module.css";

// Duplicate the images for infinite scroll effect

const ImageSlider = ({ images }) => {
  const infiniteImages = [images[images.length - 1], ...images, images[0]];
  const [currentIndex, setCurrentIndex] = useState(1);

  useEffect(() => {
    const interval = setInterval(
      () => {
        setCurrentIndex((prevIndex) => {
          if (prevIndex === infiniteImages.length - 1) {
            return 1; // Skip to the first image in the middle of the infinite loop
          }

          return prevIndex + 1;
        });
      },
      currentIndex !== 1 ? 3000 : 0
    ); // Change slide every 3 seconds
    return () => clearInterval(interval);
  }, [currentIndex]);

  return (
    <section className={classes.wrapper}>
      <div className={classes.slider}>
        <div
          className={classes.sliderImages}
          style={{
            transform: `translateX(-${currentIndex * 100}%)`,
            transition: `${
              currentIndex !== 1
                ? "transform 1s ease-in-out"
                : "transform 0s ease-in-out"
            }`,
          }}
        >
          {infiniteImages.map((image, index) => (
            <img key={index} src={image} alt={`Slide ${index + 1}`} />
          ))}
        </div>
      </div>
      <div className={classes.sliderDots}>
        {images.map((_, index) => (
          <div
            key={index}
            className={`${classes.dot} ${
              index === (currentIndex - 1 + images.length) % images.length
                ? classes.active
                : ""
            }`}
          />
        ))}
      </div>
    </section>
  );
};

export default ImageSlider;
