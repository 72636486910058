import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from 'js-cookie';

export const fetchPayments = createAsyncThunk("fetch_payments", async (args, {
    rejectWithValue
}) => {

    try {
        let base_url = process.env.REACT_APP_API_URL;
        let route = "/payments/list"
        let url = base_url + route;
        let data = await axios.get(url, {
            headers: {
                "Authorization": `Bearer ${Cookies.get("token")}`
            }
        });
        return data.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})


const fetchPaymentSlice = createSlice({
    name: 'payments',
    initialState: {
        payments: [],
        isLoading: false,
        isFailed: false
    },
    reducers: {
    },
    // extra reducer use builder callback
    extraReducers: (builder) => {
        builder.addCase(fetchPayments.pending, (state, action) => {
            state.isLoading = true
            state.isFailed = false
        })
        builder.addCase(fetchPayments.fulfilled, (state, action) => {
            state.isLoading = false
            state.isFailed = false
            state.payments = action.payload
        })
        builder.addCase(fetchPayments.rejected, (state, action) => {
            state.isLoading = false
            state.isFailed = true
        })
    }

})

export default fetchPaymentSlice.reducer