import React, { useState } from "react";
import classes from "./Security.module.css";
import { Button, Heading, Input, Text } from "components/common";
import Enable2FaModal from "Modals/Enable2FaModal/Enable2FaModal";

import { ObscuredText } from "utils/utils";

import ChangeEmailModal from "Modals/ChangeEmailModal/ChangeEmailModal";
import AddPhoneNumberModal from "Modals/AddPhoneNumberModal/AddPhoneNumberModal";
import AddPIN from "Modals/AddPinModal/AddPinModal";
import ChangePasswordModal from "Modals/ChangePasswordModal/ChangePasswordModal";

const Security = () => {
  const [email, setEmail] = useState("user@example.com");
  const [isEditingEmail, setIsEditingEmail] = useState(false);

  const [phone, setPhone] = useState("");
  const [isAddingPhone, setIsAddingPhone] = useState(false);

  const [pin, setPin] = useState("");
  const [isAddingPin, setIsAddingPin] = useState(false);

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState();
  const [isEditingPassword, setIsEditingPassword] = useState(false);

  const [is2FaEnabled, setIs2FaEnabled] = useState(false);
  const [isShow2FaEnableModal, setIsShow2FaEnableModal] = useState(false);

  const currentDate = new Date().toLocaleString("en-US", {
    month: "short",
    year: "numeric",
  });
  const [faDate, setFaDate] = useState(currentDate);

  const toggleEdit = (editSetter) => {
    editSetter((prev) => !prev);
  };

  const renderEditableField = (label, value, setIsEditing, type = "text") => (
    <div className={classes.infoContainer}>
      <Heading lg className={classes.label}>
        {label}
      </Heading>
      <Text lg semiBold secndary type="password">
        {type === password ? (
          <ObscuredText text={value} />
        ) : value ? (
          value
        ) : (
          "-"
        )}
      </Text>

      <Button
        className={classes.button}
        onClick={() => toggleEdit(setIsEditing)}
        primitive900={type === "password" || type === "email"}
        primitive100={type !== "password" && type !== "email"}
      >
        {type === "password" || type === "email" ? "Change" : "Add"}
      </Button>
    </div>
  );

  const render2FaField = () => (
    <div className={classes.infoContainer}>
      <Heading lg className={classes.label}>
        2FA
      </Heading>

      <Text lg semiBold secondary className={classes.info}>
        {faDate}
      </Text>
      <Button
        className={classes.button}
        onClick={() => {
          !is2FaEnabled && setIsShow2FaEnableModal(true);
        }}
        primitive900={is2FaEnabled}
        primitive100={!is2FaEnabled}
      >
        {is2FaEnabled ? "Disable" : "Enable"}
      </Button>
    </div>
  );

  return (
    <>
      <section className={classes.wrapper}>
        {render2FaField()}
        {renderEditableField("Email", email, setIsEditingEmail, "email")}
        {renderEditableField("Phone", phone, setIsAddingPhone)}
        {renderEditableField("PIN", pin, setIsAddingPin)}
        {renderEditableField(
          "Password",
          password,
          setIsEditingPassword,
          "password"
        )}
      </section>
      <Enable2FaModal
        isActive={isShow2FaEnableModal}
        setIs2FaEnabled={setIs2FaEnabled}
        onClose={() => setIsShow2FaEnableModal(false)}
      />
      <ChangeEmailModal
        isActive={isEditingEmail}
        onClose={() => setIsEditingEmail(false)}
        email={email}
        setEmail={setEmail}
      />{" "}
      <AddPhoneNumberModal
        isActive={isAddingPhone}
        onClose={() => setIsAddingPhone(false)}
        phone={phone}
        setPhone={setPhone}
      />{" "}
      <AddPIN
        isActive={isAddingPin}
        onClose={() => setIsAddingPin(false)}
        pin={pin}
        setPin={setPin}
      />{" "}
      <ChangePasswordModal
        isActive={isEditingPassword}
        onClose={() => setIsEditingPassword(false)}
        password={password}
        setPassword={setPassword}
        confirmPassword={confirmPassword}
        setConfirmPassword={setConfirmPassword}
      />
    </>
  );
};

export default Security;
