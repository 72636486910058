import React from "react";
import classes from "./PaymentLinkModal.module.css";
import Modal from "components/common/Modal/Modal";
import { Button, Heading, Text } from "components/common";
import { IoClose } from "react-icons/io5";

import { copyIcon } from "images";
import { copyToClipboard } from "utils/utils";
import QRCode from "react-qr-code";

const PaymentLinkModal = ({ isActive, onClose }) => {
  return (
    <Modal isActive={isActive} onClose={onClose} className={classes.modal}>
      <div className={classes.wrapper}>
        <div className={classes.header}>
          <Heading primary lg>
            Payment Link
          </Heading>
          <button className={classes.closeButton} onClick={onClose}>
            <IoClose className={classes.closeIcon} />
          </button>
        </div>

        <div className={classes.container}>
          <div className={classes.addressContainer}>
            <div className={classes.qrCodeContainer}>
              <QRCode
                size={256}
                style={{
                  height: "auto",
                  maxWidth: "100%",
                  width: "100%",
                  borderRadius: "6px",
                }}
                value={""}
                viewBox={`0 0 256 256`}
              />
              {/* <img src={qrCode} alt="QrCode" className={classes.qrCode} /> */}
            </div>
            <div className={classes.addressAndInfo}>
              <Text textWhite lg semiBold>
                Share the payment link to the sender to receive the currency.
              </Text>
              <Button primitive800>Share QR Code</Button>
            </div>
          </div>
          <div className={classes.copyAddress}>
            <Text base semiBold primary className={classes.address}>
              https://example.com/payment-id/123456
            </Text>
            <button
              className={classes.copyContainer}
              onClick={() =>
                copyToClipboard("https://example.com/payment-id/123456")
              }
            >
              <img src={copyIcon} alt="Copy" className={classes.copyIcon} />
            </button>
          </div>
        </div>
        <div className={classes.buttonContainer}>
          <Button primitive800 onClick={onClose}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              onClose();
            }}
          >
            Create Another Link
          </Button>
        </div>
      </div>
      ;
    </Modal>
  );
};

export default PaymentLinkModal;
