import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from 'js-cookie';

export const fetchTransactions = createAsyncThunk("fetch_transactions", async (args, {
    rejectWithValue
}) => {

    try {
        let base_url = process.env.REACT_APP_API_URL;
        let route = "/transactions"
        let url = base_url + route;
        console.log(url)
        let data = await axios.get(url, {
            headers: {
                "Authorization": `Bearer ${Cookies.get("token")}`
            }
        });
        console.log(data)
        return data.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})


const fetchTransactionslice = createSlice({
    name: 'transactions',
    initialState: {
        transactions: [],
        isLoading: false,
        isFailed: false
    },
    reducers: {
    },
    // extra reducer use builder callback
    extraReducers: (builder) => {
        builder.addCase(fetchTransactions.pending, (state, action) => {
            state.isLoading = true
            state.isFailed = false
        })
        builder.addCase(fetchTransactions.fulfilled, (state, action) => {
            state.isLoading = false
            state.isFailed = false
            state.transactions = action.payload
        })
        builder.addCase(fetchTransactions.rejected, (state, action) => {
            state.isLoading = false
            state.isFailed = true
        })
    }

})

export default fetchTransactionslice.reducer