import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from 'js-cookie';

export const fetchBalances = createAsyncThunk("fetch_balances", async (args, {
    rejectWithValue
}) => {

    try {
        let base_url = process.env.REACT_APP_API_URL;
        let route = "/balances"
        let url = base_url + route;
        let wallet = {
            wallet_type: "Personal"
        }
        let data = await axios.post(url, wallet, {
            headers: {
                "Authorization": `Bearer ${Cookies.get("token")}`
            }
        });
        return data.data
    } catch (error) {
        return rejectWithValue(error.response.data)
    }
})


const fetchBalanceSlice = createSlice({
    name: 'fetch_balances',
    initialState: {
        balances: [],
        isLoading: false,
        isFailed: false
    },
    reducers: {
    },
    // extra reducer use builder callback
    extraReducers: (builder) => {
        builder.addCase(fetchBalances.pending, (state, action) => {
            state.isLoading = true
            state.isFailed = false
        })
        builder.addCase(fetchBalances.fulfilled, (state, action) => {
            state.isLoading = false
            state.isFailed = false
            state.balances = action.payload
        })
        builder.addCase(fetchBalances.rejected, (state, action) => {
            state.isLoading = false
            state.isFailed = true
        })
    }

})

export default fetchBalanceSlice.reducer